// Generated by openapi-ts-gen. DO NOT EDIT
/* eslint-disable */
export interface HealthCheckResult {
  message: string
}
export interface CreateBatteryHealthRecordDto {
  ns?: string
  batteryNo?: string
  createBy?: string
  updateBy?: string
  sn: string
  battery: string
  source: string
  days: number
  months: number
  years: number
  soh: number
  calcAt: string
  dataAt: string
}
export interface BatteryHealthRecord {
  createBy?: string
  updateBy?: string
  ns: string
  sn: string
  battery: string
  batteryNo: string
  source: string
  days: number
  months: number
  years: number
  soh: number
  calcAt: string
  dataAt: string
  id: string
  createAt?: string
  updateAt?: string
}
export enum BatteryHealthRecordGroupField {
  NS = "ns",
}
export interface UpdateBatteryHealthRecordDto {
  createBy?: string
  updateBy?: string
  sn?: string
  battery?: string
  source?: string
  days?: number
  months?: number
  years?: number
  soh?: number
  calcAt?: string
  dataAt?: string
  ns?: string
  batteryNo?: string
}
export interface BatteryHealthRecordAvgResult {
  ns?: string
  sohAvg?: number
  daysAvg?: number
  count: number
}
export interface CreateBatteryDto {
  ns: string
  source?: string
  no: string
  barcode?: string
  bbox?: boolean
  capacity?: number
  lastCalcAt?: string
  lastOnlineAt?: string
  name?: string
  onsite?: boolean
  setupAt?: string
  soh?: number
  createBy?: string
  updateBy?: string
}
export interface Battery {
  createBy?: string
  updateBy?: string
  ns: string
  source?: string
  no: string
  barcode?: string
  bbox?: boolean
  capacity?: number
  lastCalcAt?: string
  lastOnlineAt?: string
  name?: string
  onsite?: boolean
  setupAt?: string
  soh?: number
  id: string
  createAt?: string
  updateAt?: string
}
export interface UpdateBatteryDto {
  createBy?: string
  updateBy?: string
  ns?: string
  source?: string
  no?: string
  barcode?: string
  bbox?: boolean
  capacity?: number
  lastCalcAt?: string
  lastOnlineAt?: string
  name?: string
  onsite?: boolean
  setupAt?: string
  soh?: number
}
export interface BatteryHealthStats {
  soh: number
  count: number
}
export enum LokiDirection {
  FORWARD = "FORWARD",
  BACKWARD = "BACKWARD",
}
export interface MessageHead {
  battBoxCode: string
  cipherFlag: MessageHead.CipherFlag
  version: string
  timeStamp: number
  deviceCode: string
  deviceType: number
}
export namespace MessageHead {
  export enum CipherFlag {
    CIPHER_FLAG_0 = 0,
    CIPHER_FLAG_1 = 1,
    CIPHER_FLAG_2 = 2,
  }
}
export interface BboxRecord {
  head: MessageHead
  dataBody: BboxRecord.DataBody
  event: string
  dataSign: string
}
export namespace BboxRecord {
  export interface DataBody {
  }
}
export interface CreateVehicleDto {
  stations?: string[]
  stationNo?: string[]
  createBy?: string
  updateBy?: string
  lastAt?: string
  labels: string[]
  lifeYear?: number
  line?: string
  listingDate?: string
  loading?: number
  manufacturer?: string
  model?: string
  modelDetail?: string
  no?: string
  ns: string
  orgCode?: string
  orgId?: string
  orgName?: string
  plate: string
  plateAt?: string
  source?: string
  vin: string
}
export interface Vehicle {
  stations: string[] | Station[]
  createBy?: string
  updateBy?: string
  lastAt?: string
  labels: string[]
  lifeYear?: number
  line?: string
  listingDate?: string
  loading?: number
  manufacturer?: string
  model?: string
  modelDetail?: string
  no?: string
  ns: string
  orgCode?: string
  orgId?: string
  orgName?: string
  plate: string
  plateAt?: string
  source?: string
  stationNo: string[]
  vin: string
  id: string
  createAt?: string
  updateAt?: string
}
export enum VehicleGroupField {
  LINE = "line",
  MANUFACTURER = "manufacturer",
  MODEL = "model",
  NS = "ns",
}
export interface UpdateVehicleDto {
  createBy?: string
  updateBy?: string
  lastAt?: string
  labels?: string[]
  lifeYear?: number
  line?: string
  listingDate?: string
  loading?: number
  manufacturer?: string
  model?: string
  modelDetail?: string
  no?: string
  ns?: string
  orgCode?: string
  orgId?: string
  orgName?: string
  plate?: string
  plateAt?: string
  source?: string
  vin?: string
  stations?: string[]
  stationNo?: string[]
}
export interface VehicleAggregateResult {
  line?: string
  manufacturer?: string
  model?: string
  ns?: string
  count: number
}
export interface GetVehicleDto {
  idOrVin: string
}
export interface ListVehiclesQuery {
  _group?: VehicleGroupField[]
  _limit?: number
  _offset?: number
  _sort?: string
  _embed?: string[]
  _expand?: string[]
  ns_like?: string
  line?: string
  manufacturer?: string
  model?: string
  modelDetail?: string
  no?: string
  ns?: string
  plate?: string
  source?: string
  stations?: string[]
  stationNo?: string[]
  vin?: string
}
export enum TboxAirMode {
  WIND = "WIND",
  OFF = "OFF",
  REFRIGERATION = "REFRIGERATION",
  HEATING = "HEATING",
  ABNORMAL = "ABNORMAL",
}
export enum VehicleStatus {
  CHARGING = "CHARGING",
  RUNNING = "RUNNING",
  STOP = "STOP",
  OFFLINE = "OFFLINE",
  UNKNOWN = "UNKNOWN",
}
export enum VehicleBattBoxStatus {
  CHARGING = "CHARGING",
  DISCHARGING = "DISCHARGING",
  OTHER = "OTHER",
  SLEEP = "SLEEP",
}
export enum TboxMotorStatus {
  CONSUMPTION = "CONSUMPTION",
  GENERATION = "GENERATION",
  OFF = "OFF",
  READY = "READY",
}
export interface TboxSingleMotor {
  status: TboxMotorStatus
  no: number
  controlTemp: number
  speed: number
  torque: number
  temp: number
  voltage: number
  current: number
}
export interface VehicleState {
  airMode?: TboxAirMode
  status: VehicleStatus
  battBoxStatus: VehicleBattBoxStatus
  ns?: string
  at?: number
  aptv?: number
  insideTemp?: number
  outsideTemp?: number
  brake?: number
  batteryNo?: string
  batteryTemp?: number
  current?: number
  discharge?: number
  location?: string
  mileage?: number
  motors?: TboxSingleMotor[]
  resistance?: number
  shift?: string
  soc?: number
  speed?: number
  vin: string
  voltage?: number
  maxCellTemp: number
  minCellTemp: number
  maxCellVolt: number
  minCellVolt: number
}
export interface UpdateVehicleStateDto {
  airMode?: TboxAirMode
  status?: VehicleStatus
  battBoxStatus?: VehicleBattBoxStatus
  ns?: string
  at?: number
  aptv?: number
  insideTemp?: number
  outsideTemp?: number
  brake?: number
  batteryNo?: string
  batteryTemp?: number
  current?: number
  discharge?: number
  location?: string
  mileage?: number
  motors?: TboxSingleMotor[]
  resistance?: number
  shift?: string
  soc?: number
  speed?: number
  vin?: string
  voltage?: number
  maxCellTemp?: number
  minCellTemp?: number
  maxCellVolt?: number
  minCellVolt?: number
}
export interface VehicleStateAggResult {
  status?: VehicleStatus
  count: number
}
export interface CreateVehicleSnapshotDto {
  createBy?: string
  updateBy?: string
  at: string
  discharge?: number
  dischargeAcc?: number
  faultCount?: number
  key: string
  line?: string
  manufacturer?: string
  mileage?: number
  mileageAcc?: number
  model?: string
  no?: string
  ns?: string
  vin: string
}
export interface VehicleSnapshot {
  createBy?: string
  updateBy?: string
  at: string
  discharge?: number
  dischargeAcc?: number
  faultCount?: number
  key: string
  line?: string
  manufacturer?: string
  mileage?: number
  mileageAcc?: number
  model?: string
  no?: string
  ns?: string
  vin: string
  id: string
  createAt?: string
  updateAt?: string
}
export enum VehicleSnapshotGroupField {
  AT_DAY = "at_day",
  AT_MONTH = "at_month",
  AT_YEAR = "at_year",
  AT_HOUR = "at_hour",
  KEY = "key",
  LINE = "line",
  MANUFACTURER = "manufacturer",
  MODEL = "model",
  NO = "no",
  NS = "ns",
  VIN = "vin",
}
export interface UpdateVehicleSnapshotDto {
  createBy?: string
  updateBy?: string
  at?: string
  discharge?: number
  dischargeAcc?: number
  faultCount?: number
  key?: string
  line?: string
  manufacturer?: string
  mileage?: number
  mileageAcc?: number
  model?: string
  no?: string
  ns?: string
  vin?: string
}
export interface DateGroup {
  year?: number
  month?: number
  week?: number
  day?: number
  hour?: number
}
export interface VehicleSnapshotAggregateResult {
  discharge: number
  faultCount: number
  mileage: number
  vehicleCount: number
  at?: DateGroup
  key?: string
  line?: string
  manufacturer?: string
  model?: string
  no?: string
  ns?: string
  vin?: string
}
export interface CreateStatusSnapshotDto {
  createBy?: string
  updateBy?: string
  at: string
  charging: number
  running: number
  stop: number
  offline: number
  unknown: number
}
export interface StatusSnapshot {
  createBy?: string
  updateBy?: string
  at: string
  charging: number
  running: number
  stop: number
  offline: number
  unknown: number
  id: string
  createAt?: string
  updateAt?: string
}
export interface UpdateStatusSnapshotDto {
  createBy?: string
  updateBy?: string
  at?: string
  charging?: number
  running?: number
  stop?: number
  offline?: number
  unknown?: number
}
export enum FaultCategory {
  STATION = "STATION",
  VEHICLE = "VEHICLE",
  BATTERY = "BATTERY",
  WARNING = "WARNING",
}
export enum FaultRecordStatus {
  BROKEN = "BROKEN",
  RECOVER = "RECOVER",
}
export enum DeviceType {
  CHARGER = "CHARGER",
  ROBOT = "ROBOT",
  STATION = "STATION",
  VEHICLE = "VEHICLE",
  BATTERY = "BATTERY",
}
export interface Device {
  type: DeviceType
  id?: string
  name?: string
  ns: string
  no?: string
  manufacturer?: string
  model?: string
}
export interface CreateFaultRecordDto {
  category: FaultCategory
  status: FaultRecordStatus
  count?: number
  brokenAt?: string
  code: string
  description?: string
  device: Device
  lastWarnAt: string
  level?: number
  line?: string
  mute?: boolean
  name?: string
  ns: string
  plate?: string
  recoverAt?: string
  sn?: string
  source?: string
  station?: string
  stationNo?: string
  timeStamp?: number
  type?: string
  vin?: string
}
export enum WorkOrderStatus {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
}
export enum WorkOrderType {
  FAULT_HANDLING = "FAULT_HANDLING",
  BATTERY_MAINTENANCE = "BATTERY_MAINTENANCE",
  STATION_MAINTENANCE = "STATION_MAINTENANCE",
}
export interface Picture {
  desc?: string
  name?: string
  url: string
}
export interface HandleResult {
  at: string
  by?: string
  desc: string
  ns?: string
  pictures: Picture[]
  stage?: string
  title?: string
  id: string
  createAt?: string
  updateAt?: string
  createBy?: string
  updateBy?: string
}
export interface FaultRecord {
  category: FaultCategory
  status: FaultRecordStatus
  brokenAt?: string
  code: string
  count: number
  description?: string
  device: Device
  lastWarnAt: string
  level?: number
  line?: string
  mute?: boolean
  name?: string
  ns: string
  plate?: string
  recoverAt?: string
  sn?: string
  source?: string
  station?: string
  stationNo?: string
  timeStamp?: number
  type?: string
  vin?: string
  workOrders: WorkOrder[]
  id: string
  createAt?: string
  updateAt?: string
  createBy?: string
  updateBy?: string
}
export interface WorkOrder {
  sheets: string[] | Sheet[]
  papers: string[] | Paper[]
  status: WorkOrderStatus
  type: WorkOrderType
  createBy?: string
  updateBy?: string
  assignees: string[]
  doneAt?: string
  desc?: string
  devices: Device[]
  pictures: Picture[]
  handleResults: HandleResult[]
  labels: string[]
  line?: string
  maintenanceOrders: string[]
  ns: string
  relatedFaults: FaultRecord[]
  stage?: string
  submitBy?: string
  submitAt?: string
  title?: string
  id: string
  createAt?: string
  updateAt?: string
}
export enum FaultRecordGroupField {
  BROKEN_AT_DAY = "brokenAt_day",
  BROKEN_AT_MONTH = "brokenAt_month",
  BROKEN_AT_YEAR = "brokenAt_year",
  BROKEN_AT_HOUR = "brokenAt_hour",
  NS = "ns",
  LEVEL = "level",
  LINE = "line",
  NAME = "name",
  PLATE = "plate",
  SOURCE = "source",
  STATION = "station",
  STATUS = "status",
  TYPE = "type",
  VIN = "vin",
  DEVICE_MANUFACTURER = "device.manufacturer",
  DEVICE_MODEL = "device.model",
}
export interface FaultRecordAggregateResult {
  status: FaultRecordStatus
  ns?: string
  name?: string
  level?: number
  line?: string
  plate?: string
  source?: string
  station?: string
  type?: string
  vin?: string
  brokenAt?: DateGroup
  "device.model"?: string
  count: number
}
export interface UpdateFaultRecordDto {
  category?: FaultCategory
  status?: FaultRecordStatus
  brokenAt?: string
  code?: string
  description?: string
  device?: Device
  lastWarnAt?: string
  level?: number
  line?: string
  mute?: boolean
  name?: string
  ns?: string
  plate?: string
  recoverAt?: string
  sn?: string
  source?: string
  station?: string
  stationNo?: string
  timeStamp?: number
  type?: string
  vin?: string
}
export enum StationMode {
  RUNNING = "RUNNING",
  TESTING = "TESTING",
  MAINTENANCE = "MAINTENANCE",
}
export enum SwapPosition {
  CABINET = "CABINET",
  A = "A",
  B = "B",
}
export enum SwapType {
  REMOTE_AUTO = "REMOTE_AUTO",
  REMOTE_MANUAL = "REMOTE_MANUAL",
  LOCAL_MANUAL = "LOCAL_MANUAL",
}
export enum OrderType {
  SWAP = "SWAP",
  CHARGE = "CHARGE",
}
export enum PeriodType {
  TOP_PEAK = "topPeak",
  PEAK = "peak",
  FLAT = "flat",
  VALLEY = "valley",
}
export interface OrderItem {
  type: PeriodType
  startAt?: string
  endAt?: string
  elecPrice: number
  servicePrice: number
  elecFee: number
  serviceFee: number
  amountFee: number
  elec: number
  id: string
  createAt?: string
  updateAt?: string
  createBy?: string
  updateBy?: string
}
export interface StopReason {
  name: string
  code: string
  level?: number
}
export interface Order {
  stationMode?: StationMode
  swapPosition?: SwapPosition
  swapType?: SwapType
  type: OrderType
  abnormal: boolean
  abnormalReason?: string
  account?: string
  at: string
  chargeEndAt?: string
  chargeSn: string
  chargeStartAt?: string
  chargeTime?: number
  connector?: string
  connectorNo?: string
  details: OrderItem[]
  driver?: string
  driverName?: string
  driverPhone?: string
  elec: number
  elecFee: number
  elecRateTemplate?: string
  endSoc?: number
  grabBatteryNo?: string
  grabBatterySOC?: number
  grabCabinetNo?: string
  isFull?: boolean
  isReissue?: boolean
  line?: string
  model?: string
  ns: string
  packageRateTemplate?: string
  plate: string
  pushBatteryNo?: string
  pushBatterySOC?: number
  pushCabinetNo?: string
  serviceRateTemplate?: string
  serviceFee: number
  source?: string
  startSoc?: number
  station: string
  stationNo?: string
  stopAbnormal?: boolean
  stopReason?: StopReason
  swapEndAt?: string
  swapStartAt?: string
  swapTime?: number
  swapSn?: string
  totalFee: number
  vehicle?: Vehicle
  vin: string
  id: string
  createAt?: string
  updateAt?: string
  createBy?: string
  updateBy?: string
}
export interface CreateBillDto {
  orders: Order[]
  packageCount: number
  createBy?: string
  updateBy?: string
  name: string
  ns: string
  startAt: string
  endAt: string
  elecTax: number
  elecRateTemplate?: string
  serviceTax: number
  serviceRateTemplate?: string
  packageTax: number
  packageRateTemplate?: string
}
export enum BillStatus {
  PENDING = "PENDING",
  READY = "READY",
}
export enum FeeType {
  TOP_PEAK = "topPeak",
  PEAK = "peak",
  FLAT = "flat",
  VALLEY = "valley",
  PACKAGE = "package",
  SERVICE = "service",
  SERVICE_DEDUCT = "serviceDeduct",
  SERVICE_EXCESS = "serviceExcess",
}
export enum FeeCategory {
  ELEC = "ELEC",
  SERVICE = "SERVICE",
  PACKAGE = "PACKAGE",
}
export interface BillItem {
  type: FeeType
  category: FeeCategory
  amount?: number
  price?: number
  quantity: number
  id: string
  createAt?: string
  updateAt?: string
  createBy?: string
  updateBy?: string
}
export interface Bill {
  status: BillStatus
  orders: string[] | Order[]
  createBy?: string
  updateBy?: string
  name: string
  ns: string
  startAt: string
  endAt: string
  swapElec: number
  chargeElec: number
  details: BillItem[]
  elecTax: number
  elecFee: number
  elecFeeWithTax: number
  elecRateTemplate?: string
  serviceTax: number
  serviceFee: number
  serviceFeeWithTax: number
  serviceRateTemplate?: string
  vehicleCount: number
  packageTax: number
  packageFee: number
  packageFeeWithTax: number
  packageRateTemplate?: string
  amount: number
  hasPackage: boolean
  id: string
  createAt?: string
  updateAt?: string
}
export interface UpdateBillDto {
  status?: BillStatus
  orders?: string[] | Order[]
  createBy?: string
  updateBy?: string
  name?: string
  ns?: string
  startAt?: string
  endAt?: string
  swapElec?: number
  chargeElec?: number
  details?: BillItem[]
  elecTax?: number
  elecFee?: number
  elecFeeWithTax?: number
  elecRateTemplate?: string
  serviceTax?: number
  serviceFee?: number
  serviceFeeWithTax?: number
  serviceRateTemplate?: string
  vehicleCount?: number
  packageTax?: number
  packageFee?: number
  packageFeeWithTax?: number
  packageRateTemplate?: string
  amount?: number
  hasPackage?: boolean
}
export interface CheckoutDto {
  name: string
  checkoutAt: string
  stations: string[]
  recalculate: boolean
  elecTax: number
  serviceTax: number
  packageTax: number
  createBy?: string
  updateBy?: string
}
export interface CreateOrderItemDto {
  type: PeriodType
  elecPrice?: number
  servicePrice?: number
  elecFee?: number
  serviceFee?: number
  amountFee?: number
  startAt?: string
  endAt?: string
  elec: number
}
export interface CreateOrderDto {
  stationMode?: StationMode
  swapPosition?: SwapPosition
  swapType?: SwapType
  type: OrderType
  details: CreateOrderItemDto[]
  elecFee?: number
  serviceFee?: number
  totalFee?: number
  abnormal?: boolean
  abnormalReason?: string
  account?: string
  at: string
  chargeEndAt?: string
  chargeSn: string
  chargeStartAt?: string
  chargeTime?: number
  connector?: string
  connectorNo?: string
  driver?: string
  driverName?: string
  driverPhone?: string
  elec: number
  elecRateTemplate?: string
  endSoc?: number
  grabBatteryNo?: string
  grabBatterySOC?: number
  grabCabinetNo?: string
  isFull?: boolean
  isReissue?: boolean
  line?: string
  model?: string
  ns: string
  packageRateTemplate?: string
  plate: string
  pushBatteryNo?: string
  pushBatterySOC?: number
  pushCabinetNo?: string
  serviceRateTemplate?: string
  source?: string
  startSoc?: number
  station: string
  stationNo?: string
  stopAbnormal?: boolean
  stopReason?: StopReason
  swapEndAt?: string
  swapStartAt?: string
  swapTime?: number
  swapSn?: string
  vehicle?: Vehicle
  vin: string
}
export enum OrderGroupField {
  AT_DAY = "at_day",
  AT_MONTH = "at_month",
  AT_YEAR = "at_year",
  AT_HOUR = "at_hour",
  GRAB_BATTERY_NO = "grabBatteryNo",
  IS_FULL = "isFull",
  NS = "ns",
  LINE = "line",
  PLATE = "plate",
  SOURCE = "source",
  STATION = "station",
  STOP_REASON_NAME = "stopReason.name",
  VIN = "vin",
}
export enum OrderCountableField {
  DETAILS = "details",
  ELEC = "elec",
  CHARGE_TIME = "chargeTime",
  SWAP_TIME = "swapTime",
  VEHICLE = "vehicle",
}
export interface OrderAggregateDetail {
  type: PeriodType
  elec: number
}
export interface OrderAggregateResult {
  chargeTime?: number
  count: number
  details?: OrderAggregateDetail[]
  elec?: number
  swapTime?: number
  vehicleCount?: number
  at?: DateGroup
  grabBatteryNo?: string
  isFull?: boolean
  line?: string
  ns?: string
  plate?: string
  source?: string
  station?: string
  "stopReason.name"?: string
  vin?: string
}
export interface UpdateOrderDto {
  stationMode?: StationMode
  swapPosition?: SwapPosition
  swapType?: SwapType
  type?: OrderType
  abnormalReason?: string
  account?: string
  at?: string
  chargeEndAt?: string
  chargeSn?: string
  chargeStartAt?: string
  chargeTime?: number
  connector?: string
  connectorNo?: string
  driver?: string
  driverName?: string
  driverPhone?: string
  elec?: number
  elecRateTemplate?: string
  endSoc?: number
  grabBatteryNo?: string
  grabBatterySOC?: number
  grabCabinetNo?: string
  isFull?: boolean
  isReissue?: boolean
  line?: string
  model?: string
  ns?: string
  packageRateTemplate?: string
  plate?: string
  pushBatteryNo?: string
  pushBatterySOC?: number
  pushCabinetNo?: string
  serviceRateTemplate?: string
  source?: string
  startSoc?: number
  station?: string
  stationNo?: string
  stopAbnormal?: boolean
  stopReason?: StopReason
  swapEndAt?: string
  swapStartAt?: string
  swapTime?: number
  swapSn?: string
  vehicle?: Vehicle
  vin?: string
  details?: CreateOrderItemDto[]
  elecFee?: number
  serviceFee?: number
  totalFee?: number
  abnormal?: boolean
}
export interface GetOrderBySwapSnDto {
  swapSn: string
  source?: string
}
export enum RateTemplateType {
  ELECTRIC = "ELECTRIC",
  SERVICE = "SERVICE",
  PACKAGE = "PACKAGE",
}
export interface ElecTip {
  topPeak: number
  peak: number
  flat: number
  valley: number
}
export interface Package {
  elec: number
  fee: number
}
export interface CreateRateTemplateDto {
  type: RateTemplateType
  name: string
  elecFee?: ElecTip
  serviceFee?: number
  package?: Package
  createBy?: string
  updateBy?: string
}
export interface RateTemplate {
  type: RateTemplateType
  createBy?: string
  updateBy?: string
  name: string
  elecFee?: ElecTip
  serviceFee?: number
  package?: Package
  id: string
  createAt?: string
  updateAt?: string
}
export interface UpdateRateTemplateDto {
  type?: RateTemplateType
  createBy?: string
  updateBy?: string
  name?: string
  elecFee?: ElecTip
  serviceFee?: number
  package?: Package
}
export enum StationStatus {
  UNKNOWN = "UNKNOWN",
  UNDER_CONSTRUCTION = "UNDER_CONSTRUCTION",
  CLOSED = "CLOSED",
  MAINTAINING = "MAINTAINING",
  OPEN = "OPEN",
}
export enum StationType {
  UNKNOWN = "UNKNOWN",
  CHARGER = "CHARGER",
  SWAP = "SWAP",
  HYBIRD = "HYBIRD",
}
export enum RobotType {
  GANTRY = "GANTRY",
  ARM = "ARM",
  SIDE = "SIDE",
}
export interface RobotDoc {
  type: RobotType
  ns?: string
  source?: string
  no: string
}
export enum ModuleType {
  KW_15 = "KW_15",
  KW_20 = "KW_20",
  KW_30 = "KW_30",
}
export interface ChargerDoc {
  moduleType?: ModuleType
  ns?: string
  source?: string
  no: string
  name: string
  power?: number
  moduleManufacturer?: string
}
export interface TimeRange {
  title?: string
  start: string
  end: string
  desc?: string
}
export interface ConnectorNum {
  OFFLINE: number
  IDLE: number
  OCCUPIED_NOT_CHARGING: number
  OCCUPIED_CHARGING: number
  OCCUPIED_ORDERED: number
  ERROR: number
  total: number
}
export interface Position {
  lat: number
  lng: number
}
export interface CreateStationDto {
  status?: StationStatus
  type?: StationType
  ns?: string
  quotaPerHour?: number
  socThreshold?: number
  robots?: RobotDoc[]
  chargers?: ChargerDoc[]
  shiftPeriods?: TimeRange[]
  address?: string
  batteryRentPrice?: number
  busineHours?: string
  cabinetNum?: number
  capacity?: number
  city?: string
  connectorNum?: ConnectorNum
  countryCode?: string
  areaCode?: string
  cubicleNum?: number
  district?: string
  elecRateTemplate?: string
  endTime?: string
  images?: string[]
  lastCheckOutAt?: string
  name: string
  no: string[]
  parkNum?: number
  pileNum?: number
  position?: Position
  power?: number
  province?: string
  Remark?: string
  serviceRateTemplate?: string
  serviceVehicleNum?: number
  serviceVehicleType?: number
  setUpAt?: string
  shiftExpires?: number
  source: string[]
  startTime?: string
  supportOrder?: boolean
  vehicleNum?: number
}
export interface Charger {
  moduleType?: ModuleType
  ns?: string
  source?: string
  no: string
  name: string
  power?: number
  moduleManufacturer?: string
}
export interface Robot {
  type: RobotType
  ns?: string
  source?: string
  no: string
}
export interface Station {
  status: StationStatus
  type: StationType
  address?: string
  availabeBatteryNum: number
  batteryRentPrice?: number
  busineHours?: string
  cabinetNum?: number
  capacity?: number
  city?: string
  connectorNum?: ConnectorNum
  chargerNum: number
  chargers: Charger[]
  countryCode?: string
  areaCode?: string
  cubicleNum?: number
  district?: string
  elecRateTemplate?: string
  endTime?: string
  images?: string[]
  lastCheckOutAt?: string
  name: string
  no: string[]
  ns: string
  parkNum?: number
  pileNum?: number
  position?: Position
  power?: number
  province?: string
  quotaPerHour: number
  Remark?: string
  robotNum: number
  robots: Robot[]
  serviceRateTemplate?: string
  serviceVehicleNum?: number
  serviceVehicleType?: number
  setUpAt?: string
  shiftExpires?: number
  shiftPeriods: TimeRange[]
  socThreshold: number
  source: string[]
  startTime?: string
  supportOrder?: boolean
  vehicleNum?: number
  id: string
  createAt?: string
  updateAt?: string
  createBy?: string
  updateBy?: string
}
export enum ListStationsCountableField {
  PILE = "pile",
  VEHICLE = "vehicle",
  CONNECTOR = "connector",
  BATTERY = "battery",
}
export interface UpdateStationDto {
  status?: StationStatus
  type?: StationType
  address?: string
  batteryRentPrice?: number
  busineHours?: string
  cabinetNum?: number
  capacity?: number
  city?: string
  connectorNum?: ConnectorNum
  countryCode?: string
  areaCode?: string
  cubicleNum?: number
  district?: string
  elecRateTemplate?: string
  endTime?: string
  images?: string[]
  lastCheckOutAt?: string
  name?: string
  no?: string[]
  parkNum?: number
  pileNum?: number
  position?: Position
  power?: number
  province?: string
  Remark?: string
  serviceRateTemplate?: string
  serviceVehicleNum?: number
  serviceVehicleType?: number
  setUpAt?: string
  shiftExpires?: number
  source?: string[]
  startTime?: string
  supportOrder?: boolean
  vehicleNum?: number
  ns?: string
  quotaPerHour?: number
  socThreshold?: number
  robots?: RobotDoc[]
  chargers?: ChargerDoc[]
  shiftPeriods?: TimeRange[]
}
export interface ReserveSlot {
  id: string
  startAt: string
  endAt: string
  remaining: number
  total: number
}
export interface JiuXingStation {
  Id: string
  name: string
  No: string
}
export interface StationMonitor {
  iddevice: string
  devicename: string
  ptztype: number
  brief: string
}
export interface DeviceFlv {
  streamid: string
  flv: string
}
export interface PlaybackMonitorDto {
  deviceId: string
  startTime: string
}
export interface StopPlaybackMonitorDto {
  streamId: string
}
export interface UpsertStationDto {
  status?: StationStatus
  type?: StationType
  no: string
  source: string
  address?: string
  batteryRentPrice?: number
  busineHours?: string
  cabinetNum?: number
  capacity?: number
  city?: string
  connectorNum?: ConnectorNum
  countryCode?: string
  areaCode?: string
  cubicleNum?: number
  district?: string
  elecRateTemplate?: string
  endTime?: string
  images?: string[]
  lastCheckOutAt?: string
  name: string
  parkNum?: number
  pileNum?: number
  position?: Position
  power?: number
  province?: string
  Remark?: string
  serviceRateTemplate?: string
  serviceVehicleNum?: number
  serviceVehicleType?: number
  setUpAt?: string
  shiftExpires?: number
  startTime?: string
  supportOrder?: boolean
  vehicleNum?: number
  ns?: string
  quotaPerHour?: number
  socThreshold?: number
  robots?: RobotDoc[]
  chargers?: ChargerDoc[]
  shiftPeriods?: TimeRange[]
}
export interface GetStationByNoDto {
  no: string
  source?: string
}
export interface ListStationsQuery {
  type?: StationType
  _count?: ListStationsCountableField[]
  _limit?: number
  _offset?: number
  _sort?: string
  ns_like?: string
  name_like?: string
  name?: string
  ns?: string
  no?: string[]
  source?: string[]
}
export enum CabinetStatus {
  UNKNOWN = "UNKNOWN",
  NORMAL = "NORMAL",
  REPAIR = "REPAIR",
  OFFLINE = "OFFLINE",
}
export enum ChargerStatus {
  UNKNOWN = "UNKNOWN",
  STANDBY = "STANDBY",
  CHARGING = "CHARGING",
  FULLY = "FULLY",
  ERROR = "ERROR",
  OFFLINE = "OFFLINE",
}
export interface Fault {
  category?: FaultCategory
  code: string
  name?: string
  level?: number
  type?: string
}
export interface ChargerState {
  cabinetStatus: CabinetStatus
  status: ChargerStatus
  no: string
  name?: string
  batteryNo?: string
  batteryFaults: Fault[]
  cabinetNo?: string
  current?: number
  faults: Fault[]
  soc?: number
  voltage?: number
}
export enum RobotStatus {
  UNKNOWN = "UNKNOWN",
  STANDBY = "STANDBY",
  WORKING = "WORKING",
  ERROR = "ERROR",
  OFFLINE = "OFFLINE",
}
export interface RobotState {
  status: RobotStatus
  no: string
  faults: Fault[]
}
export interface StationState {
  name?: string
  no: string
  beatAt?: string
  chargers: ChargerState[]
  robots: RobotState[]
}
export interface UpdateStationStateDto {
  no: string
  name?: string
  beatAt?: string
  chargers?: ChargerState[]
  robots?: RobotState[]
}
export interface RpcGetStationStateDto {
  no: string
}
export enum PileType {
  DC = "DC",
  AC = "AC",
  AC_AND_DC = "AC_AND_DC",
  WIRELESS = "WIRELESS",
  OTHER = "OTHER",
}
export interface CreatePileDto {
  type: PileType
  ns?: string
  manufacturer?: string
  model?: string
  name?: string
  no: string
  position?: Position
  power: number
  productionDate: string
  source?: string
  station: string
  stationNo?: string
}
export interface Pile {
  type: PileType
  manufacturer?: string
  model?: string
  name?: string
  no: string
  ns: string
  position?: Position
  power: number
  productionDate: string
  source?: string
  station: string
  stationNo?: string
  id: string
  createAt?: string
  updateAt?: string
  createBy?: string
  updateBy?: string
}
export interface UpdatePileDto {
  type?: PileType
  manufacturer?: string
  model?: string
  name?: string
  no?: string
  position?: Position
  power?: number
  productionDate?: string
  source?: string
  station?: string
  stationNo?: string
  ns?: string
}
export enum ConnectorType {
  HOUSEHOLD_SOCKET = "HOUSEHOLD_SOCKET",
  AC_INTERFACE_SOCKET = "AC_INTERFACE_SOCKET",
  AC_INTERFACE_PLUG = "AC_INTERFACE_PLUG",
  DC_INTERFACE_TIP = "DC_INTERFACE_TIP",
  WIRELESS_CHARGING_STAND = "WIRELESS_CHARGING_STAND",
  OTHER = "OTHER",
}
export enum LockStatus {
  UNKNOWN = "UNKNOWN",
  UNLOCK = "UNLOCK",
  LOCKED = "LOCKED",
}
export enum ParkStatus {
  UNKNOWN = "UNKNOWN",
  IDLE = "IDLE",
  OCCUPIED = "OCCUPIED",
}
export enum ConnectorStatus {
  OFFLINE = "OFFLINE",
  IDLE = "IDLE",
  OCCUPIED_NOT_CHARGING = "OCCUPIED_NOT_CHARGING",
  OCCUPIED_CHARGING = "OCCUPIED_CHARGING",
  OCCUPIED_ORDERED = "OCCUPIED_ORDERED",
  ERROR = "ERROR",
}
export interface CreateConnectorDto {
  type: ConnectorType
  lockStatus?: LockStatus
  parkStatus?: ParkStatus
  status?: ConnectorStatus
  ns?: string
  current: number
  name?: string
  nationalStandard?: string
  no: string
  parkNo?: string
  pileNo: string
  power: number
  source?: string
  station: string
  stationNo?: string
  voltageUpperLimits?: number
  voltageLowerLimits?: number
}
export interface Connector {
  lockStatus: LockStatus
  parkStatus: ParkStatus
  status: ConnectorStatus
  type: ConnectorType
  current: number
  name?: string
  nationalStandard?: string
  no: string
  ns: string
  parkNo?: string
  pileNo: string
  power: number
  source?: string
  station: string
  stationNo?: string
  voltageUpperLimits?: number
  voltageLowerLimits?: number
  id: string
  createAt?: string
  updateAt?: string
  createBy?: string
  updateBy?: string
}
export enum ConnectorGroupField {
  STATUS = "status",
}
export enum ChargeDeviceType {
  CHARGER = "CHARGER",
  CONNECTOR = "CONNECTOR",
}
export enum ChargeStatus {
  STARTING = "STARTING",
  CHARGING = "CHARGING",
  STOPING = "STOPING",
  STOPED = "STOPED",
  UNKNOWN = "UNKNOWN",
}
export interface ChargeState {
  deviceType: ChargeDeviceType
  chargeStatus?: ChargeStatus
  connectorStatus?: ConnectorStatus
  deviceNo: string
  source?: string
  chargeSn?: string
  current?: number
  voltage?: number
  soc: number
  startAt: string
  endAt: string
  totalPower: number
  elecFee?: number
  seviceFee?: number
  stationNo?: string
  totalFee?: number
  vin?: string
  plate?: string
  beatAt?: string
}
export interface UpdateConnectorDto {
  type?: ConnectorType
  lockStatus?: LockStatus
  parkStatus?: ParkStatus
  status?: ConnectorStatus
  current?: number
  name?: string
  nationalStandard?: string
  no?: string
  parkNo?: string
  pileNo?: string
  power?: number
  source?: string
  station?: string
  stationNo?: string
  voltageUpperLimits?: number
  voltageLowerLimits?: number
  ns?: string
}
export interface GetConnectorByNoDto {
  no: string
  source?: string
}
export enum NamespaceLabel {
  TRANSPORT_COMPANY = "TRANSPORT_COMPANY",
  MAINTENANCE_COMPANY = "MAINTENANCE_COMPANY",
  ZONE = "ZONE",
}
export interface CreateCompanyDto {
  type?: NamespaceLabel
  account?: string
  batteryCount?: number
  code: string
  driverCount?: number
  elecRateTemplate?: string
  maintainerCount?: number
  name: string
  ns: string
  packageRateTemplate?: string
  packageCount?: number
  serviceRateTemplate?: string
  stations?: string[]
  vehicleCount?: number
}
export interface Company {
  type?: NamespaceLabel
  account?: string
  batteryCount?: number
  code: string
  driverCount?: number
  elecRateTemplate?: string
  maintainerCount?: number
  name: string
  ns: string
  packageRateTemplate?: string
  packageCount?: number
  serviceRateTemplate?: string
  stations?: string[]
  vehicleCount?: number
  id: string
  createAt?: string
  updateAt?: string
  createBy?: string
  updateBy?: string
}
export enum ListCompanyCountableField {
  DRIVER = "driver",
  MAINTAINER = "maintainer",
  VEHICLE = "vehicle",
  BATTERY = "battery",
}
export interface UpdateCompanyDto {
  name?: string
  account?: string
  elecRateTemplate?: string
  serviceRateTemplate?: string
  packageRateTemplate?: string
  packageCount?: number
  stations?: string[]
}
export interface GetCompanyDto {
  id: string
}
export interface CreateDriverDto {
  roles?: string[]
  id: string
  ns: string
  name: string
  phone: string
}
export interface Driver {
  id: string
  ns: string
  name: string
  phone: string
  roles: string[]
  createAt?: string
  updateAt?: string
  createBy?: string
  updateBy?: string
}
export interface UpdateDriverDto {
  id?: string
  ns?: string
  name?: string
  phone?: string
  roles?: string[]
}
export interface CreateMaintainerDto {
  roles?: string[]
  id: string
  ns: string
  name: string
  phone: string
}
export interface Maintainer {
  id: string
  ns: string
  name: string
  phone: string
  roles: string[]
  createAt?: string
  updateAt?: string
  createBy?: string
  updateBy?: string
}
export interface UpdateMaintainerDto {
  id?: string
  ns?: string
  name?: string
  phone?: string
  roles?: string[]
}
export interface CreateChargeRecordDto {
  chargeSwitch: OrderType
  ns: string
  source?: string
  batteryNo: string
  chargeElec: number
  chargerNo: string
  duration: number
  endAt: string
  flatElec: number
  peakElec: number
  plate: string
  sn: string
  startAt: string
  startElec: number
  startSoc: number
  station: string
  stationNo?: string
  stopElec: number
  stopReason: StopReason
  stopSoc: number
  swapSn: string
  topPeakElec: number
  valleyElec: number
  vin: string
}
export interface ChargeRecord {
  chargeSwitch: OrderType
  ns: string
  source?: string
  batteryNo: string
  chargeElec: number
  chargerNo: string
  duration: number
  endAt: string
  flatElec: number
  peakElec: number
  plate: string
  sn: string
  startAt: string
  startElec: number
  startSoc: number
  station: string
  stationNo?: string
  stopElec: number
  stopReason: StopReason
  stopSoc: number
  swapSn: string
  topPeakElec: number
  valleyElec: number
  vin: string
  id: string
  createAt?: string
  updateAt?: string
  createBy?: string
  updateBy?: string
}
export interface UpdateChargeRecordDto {
  chargeSwitch?: OrderType
  ns?: string
  source?: string
  batteryNo?: string
  chargeElec?: number
  chargerNo?: string
  duration?: number
  endAt?: string
  flatElec?: number
  peakElec?: number
  plate?: string
  sn?: string
  startAt?: string
  startElec?: number
  startSoc?: number
  station?: string
  stationNo?: string
  stopElec?: number
  stopReason?: StopReason
  stopSoc?: number
  swapSn?: string
  topPeakElec?: number
  valleyElec?: number
  vin?: string
}
export interface CreateOperationDto {
  origin?: string
  params?: string
  query?: string
  body?: string
  domain?: string
  error?: string
  method?: string
  name: string
  ns?: string
  path?: string
  success?: boolean
  user?: string
}
export interface Operation {
  origin?: string
  params?: string
  query?: string
  body?: string
  domain?: string
  error?: string
  method?: string
  name: string
  ns?: string
  path?: string
  success?: boolean
  user?: string
  id: string
  createAt?: string
  updateAt?: string
  createBy?: string
  updateBy?: string
}
export interface UpdateOperationDto {
  origin?: string
  params?: string
  query?: string
  body?: string
  domain?: string
  error?: string
  method?: string
  name?: string
  ns?: string
  path?: string
  success?: boolean
  user?: string
}
export interface PaperFieldDoc {
  value: string
  categories?: string[]
  remark?: string
  key: string
  title: string
}
export interface CreatePaperDto {
  fields: PaperFieldDoc[]
  labels?: string[]
  createBy?: string
  updateBy?: string
  sheet: string
}
export interface PaperField {
  value: string
  categories?: string[]
  remark?: string
  key: string
  title: string
  id: string
  createAt?: string
  updateAt?: string
  createBy?: string
  updateBy?: string
}
export interface Paper {
  createBy?: string
  updateBy?: string
  fields: PaperField[]
  sheet: string
  labels: string[]
  id: string
  createAt?: string
  updateAt?: string
}
export interface UpdatePaperDto {
  createBy?: string
  updateBy?: string
  sheet?: string
  fields?: PaperFieldDoc[]
  labels?: string[]
}
export enum ReservationStatus {
  PENDING = "PENDING",
  DONE = "DONE",
  CANCEL = "CANCEL",
}
export interface CreateReservationDto {
  status?: ReservationStatus
  station: string
  ns: string
  driver: string
  driverName: string
  driverPhone?: string
  plate: string
  reserveTime: string
  swapAt?: string
  swapSn?: string
}
export interface Reservation {
  status: ReservationStatus
  ns: string
  driver: string
  driverName: string
  driverPhone?: string
  plate: string
  reserveTime: string
  station: string
  stationName: string
  swapAt?: string
  swapSn?: string
  overdue: boolean
  id: string
  createAt?: string
  updateAt?: string
  createBy?: string
  updateBy?: string
}
export enum ReservationGroupField {
  STATUS = "status",
  NS = "ns",
  PLATE = "plate",
  STATION = "station",
  VIN = "vin",
}
export interface ReservationAggregateResult {
  status?: ReservationStatus
  vin?: string
  plate?: string
  ns?: string
  station?: string
  elec: number
  count: number
}
export interface UpdateReservationDto {
  status?: ReservationStatus
  ns?: string
  driver?: string
  driverName?: string
  driverPhone?: string
  plate?: string
  reserveTime?: string
  swapAt?: string
  swapSn?: string
  station?: string
}
export enum SheetFieldType {
  INPUT = "INPUT",
  SELECT = "SELECT",
  RADIO = "RADIO",
  CHECKBOX = "CHECKBOX",
  DATE = "DATE",
  UPLOAD = "UPLOAD",
  TITLE = "TITLE",
}
export interface SheetFieldDoc {
  type: SheetFieldType
  defaultValue?: string
  description?: string
  categories?: string[]
  key?: string
  options?: string[]
  placeholder?: string
  tooltip?: string
  required?: boolean
  title: string
}
export interface CreateSheetDto {
  fields: SheetFieldDoc[]
  labels?: string[]
  createBy?: string
  updateBy?: string
  isTemplate?: boolean
  title: string
}
export interface SheetField {
  type: SheetFieldType
  defaultValue?: string
  description?: string
  categories?: string[]
  key?: string
  options?: string[]
  placeholder?: string
  tooltip?: string
  required?: boolean
  title: string
  id: string
  createAt?: string
  updateAt?: string
  createBy?: string
  updateBy?: string
}
export interface Sheet {
  createBy?: string
  updateBy?: string
  fields: SheetField[]
  isTemplate?: boolean
  labels: string[]
  title: string
  id: string
  createAt?: string
  updateAt?: string
}
export interface UpdateSheetDto {
  createBy?: string
  updateBy?: string
  isTemplate?: boolean
  title?: string
  fields?: SheetFieldDoc[]
  labels?: string[]
}
export enum StaffShiftStatus {
  OPEN = "OPEN",
  CLOSE = "CLOSE",
}
export interface CreateStaffShiftDto {
  status?: StaffShiftStatus
  pictures?: Picture[]
  remarks?: string[]
  successors?: string[]
  abnormal: boolean
  checked?: boolean
  desc?: string
  expireAt?: string
  expired?: boolean
  ns: string
  station?: string
  stationNo?: string
  shiftAt?: string
  submitAt: string
  submitBy: string
}
export interface StaffShift {
  status: StaffShiftStatus
  abnormal: boolean
  checked?: boolean
  desc?: string
  expireAt?: string
  expired?: boolean
  ns: string
  pictures: Picture[]
  remarks: string[]
  station?: string
  stationNo?: string
  shiftAt?: string
  submitAt: string
  submitBy: string
  successors: string[]
  id: string
  createAt?: string
  updateAt?: string
  createBy?: string
  updateBy?: string
}
export enum StaffShiftExpandField {
  STATION = "station",
}
export enum StaffShiftGroupField {
  ABNORMAL = "abnormal",
  SUBMIT_AT_DAY = "submitAt_day",
  SUBMIT_AT_MONTH = "submitAt_month",
  SUBMIT_AT_YEAR = "submitAt_year",
  SUBMIT_AT_HOUR = "submitAt_hour",
  NS = "ns",
  STATION = "station",
  STATUS = "status",
}
export interface StaffShiftAggregateResult {
  status: StaffShiftStatus
  abnormal: boolean
  station?: string
  ns?: string
  submitAt?: DateGroup
  count: number
}
export interface UpdateStaffShiftDto {
  status?: StaffShiftStatus
  abnormal?: boolean
  checked?: boolean
  desc?: string
  expireAt?: string
  expired?: boolean
  ns?: string
  station?: string
  stationNo?: string
  shiftAt?: string
  submitAt?: string
  submitBy?: string
  pictures?: Picture[]
  remarks?: string[]
  successors?: string[]
}
export interface ConfirmStaffShiftDto {
  desc?: string
  remark?: string
  successor: string
}
export interface CreateSwapRecordDto {
  stationMode?: StationMode
  swapPosition: SwapPosition
  swapType: SwapType
  source?: string
  account?: string
  batteryRentFee?: number
  chargeSn?: string[]
  currCEC?: number
  currDischargeElec?: number
  currFeedbackElec?: number
  currPileChargeElec?: number
  driver?: string
  driverName?: string
  driverPhone?: string
  endAt: string
  grabBatteryNo: string
  grabBatterySOC: number
  grabCabinetNo: string
  ns: string
  plate: string
  pushBatteryNo: string
  pushBatterySOC: number
  pushCabinetNo: string
  robotNo: string
  sn: string
  startAt: string
  station: string
  stationNo?: string
  timeStamp: string
  vin: string
}
export interface SwapRecord {
  stationMode?: StationMode
  swapPosition: SwapPosition
  swapType: SwapType
  source?: string
  account?: string
  batteryRentFee?: number
  chargeSn?: string[]
  currCEC?: number
  currDischargeElec?: number
  currFeedbackElec?: number
  currPileChargeElec?: number
  driver?: string
  driverName?: string
  driverPhone?: string
  endAt: string
  grabBatteryNo: string
  grabBatterySOC: number
  grabCabinetNo: string
  ns: string
  plate: string
  pushBatteryNo: string
  pushBatterySOC: number
  pushCabinetNo: string
  robotNo: string
  sn: string
  startAt: string
  station: string
  stationNo?: string
  timeStamp: string
  vin: string
  id: string
  createAt?: string
  updateAt?: string
  createBy?: string
  updateBy?: string
}
export interface UpdateSwapRecordDto {
  stationMode?: StationMode
  swapPosition?: SwapPosition
  swapType?: SwapType
  source?: string
  account?: string
  batteryRentFee?: number
  chargeSn?: string[]
  currCEC?: number
  currDischargeElec?: number
  currFeedbackElec?: number
  currPileChargeElec?: number
  driver?: string
  driverName?: string
  driverPhone?: string
  endAt?: string
  grabBatteryNo?: string
  grabBatterySOC?: number
  grabCabinetNo?: string
  ns?: string
  plate?: string
  pushBatteryNo?: string
  pushBatterySOC?: number
  pushCabinetNo?: string
  robotNo?: string
  sn?: string
  startAt?: string
  station?: string
  stationNo?: string
  timeStamp?: string
  vin?: string
}
export interface GetSwapRecordBySnDto {
  sn: string
  source?: string
}
export interface UpdateSwapRecordBySnDto {
  stationMode?: StationMode
  swapPosition?: SwapPosition
  swapType?: SwapType
  source?: string
  account?: string
  batteryRentFee?: number
  chargeSn?: string[]
  currCEC?: number
  currDischargeElec?: number
  currFeedbackElec?: number
  currPileChargeElec?: number
  driver?: string
  driverName?: string
  driverPhone?: string
  endAt?: string
  grabBatteryNo?: string
  grabBatterySOC?: number
  grabCabinetNo?: string
  ns?: string
  plate?: string
  pushBatteryNo?: string
  pushBatterySOC?: number
  pushCabinetNo?: string
  robotNo?: string
  sn?: string
  startAt?: string
  station?: string
  stationNo?: string
  timeStamp?: string
  vin?: string
}
export interface CreateWastageDto {
  ns?: string
  createBy?: string
  updateBy?: string
  endAt?: string
  elecMeter: number
  elecCharge: number
  elecOther?: number
  elecWaste: number
  month: string
  startAt?: string
  station: string
  wastePercentage: number
}
export interface Wastage {
  createBy?: string
  updateBy?: string
  ns: string
  endAt?: string
  elecMeter: number
  elecCharge: number
  elecOther?: number
  elecWaste: number
  month: string
  startAt?: string
  station: string
  wastePercentage: number
  id: string
  createAt?: string
  updateAt?: string
}
export interface UpdateWastageDto {
  createBy?: string
  updateBy?: string
  endAt?: string
  elecMeter?: number
  elecCharge?: number
  elecOther?: number
  elecWaste?: number
  month?: string
  startAt?: string
  station?: string
  wastePercentage?: number
  ns?: string
}
export interface HandleResultDoc {
  at: string
  by?: string
  desc: string
  ns?: string
  pictures: Picture[]
  stage?: string
  title?: string
}
export interface CreateWorkOrderDto {
  type: WorkOrderType
  assignees?: string[]
  devices?: Device[]
  handleResults?: HandleResultDoc[]
  labels?: string[]
  pictures?: Picture[]
  relatedFaults?: string[]
  maintenanceOrders?: string[]
  sheets?: string[]
  template?: string
  papers?: string[]
  createBy?: string
  updateBy?: string
  desc?: string
  line?: string
  ns: string
  stage?: string
  submitBy?: string
  submitAt?: string
  title?: string
}
export interface batchCreateWorkOrderResult {
  total: number
}
export enum WorkOrderGroupField {
  CREATE_AT_DAY = "createAt_day",
  CREATE_AT_MONTH = "createAt_month",
  CREATE_AT_YEAR = "createAt_year",
  CREATE_AT_HOUR = "createAt_hour",
  DEVICES_MANUFACTURER = "devices.manufacturer",
  DEVICES_MODEL = "devices.model",
  LINE = "line",
  NS = "ns",
  STATUS = "status",
  STAGE = "stage",
}
export interface WorkOrderAggregateResult {
  status?: WorkOrderStatus
  createAt?: DateGroup
  "devices.model"?: string
  "devices.manufacturer"?: string
  line?: string
  ns?: string
  stage?: string
  count: number
}
export interface UpdateWorkOrderDto {
  sheets?: string[] | Sheet[]
  papers?: string[] | Paper[]
  status?: WorkOrderStatus
  type?: WorkOrderType
  handleResults?: HandleResultDoc[]
  relatedFaults?: string[]
  createBy?: string
  updateBy?: string
  assignees?: string[]
  doneAt?: string
  desc?: string
  devices?: Device[]
  pictures?: Picture[]
  labels?: string[]
  line?: string
  maintenanceOrders?: string[]
  ns?: string
  stage?: string
  submitBy?: string
  submitAt?: string
  title?: string
}
export interface SubmitWorkOrderDto {
  status?: WorkOrderStatus
  type?: WorkOrderType
  papers?: CreatePaperDto[]
  createBy?: string
  updateBy?: string
  assignees?: string[]
  doneAt?: string
  desc?: string
  devices?: Device[]
  pictures?: Picture[]
  labels?: string[]
  line?: string
  maintenanceOrders?: string[]
  ns?: string
  stage?: string
  submitBy?: string
  submitAt?: string
  title?: string
  handleResults?: HandleResultDoc[]
  relatedFaults?: string[]
}
export interface HandleWorkOrderDto {
  pictures?: Picture[]
  at: string
  by?: string
  desc: string
  ns?: string
  stage?: string
  title?: string
}
export enum TboxCommand {
  VEHICLE_LOGIN = "VEHICLE_LOGIN",
  REALTIME_REPORT = "REALTIME_REPORT",
  REISSUE_REPORT = "REISSUE_REPORT",
  VEHICLE_LOGOUT = "VEHICLE_LOGOUT",
  PLATFORM_LOGIN = "PLATFORM_LOGIN",
  PLATFORM_LOGOUT = "PLATFORM_LOGOUT",
  HEARTBEAT = "HEARTBEAT",
  TIME = "TIME",
}
export enum TboxFlag {
  SUCCESS = "SUCCESS",
  FAIL = "FAIL",
  VIN_DUP = "VIN_DUP",
  COMMAND = "COMMAND",
}
export enum TboxInfoType {
  OVERALL = "OVERALL",
  MOTOR = "MOTOR",
  FUELCELL = "FUELCELL",
  ENGINE = "ENGINE",
  LOCATION = "LOCATION",
  EXTREME = "EXTREME",
  ALARM = "ALARM",
  RESS_VOLTAGE = "RESS_VOLTAGE",
  RESS_TEMPERATURE = "RESS_TEMPERATURE",
  CUSTOM_EXT = "CUSTOM_EXT",
  TEN_SECONDS = "TEN_SECONDS",
  ADAS = "ADAS",
  ADAS2 = "ADAS2",
  ENERGY = "ENERGY",
  CHARGING_BOW = "CHARGING_BOW",
}
export enum TboxOverallStatus {
  ON = "ON",
  OFF = "OFF",
  OTHER = "OTHER",
}
export enum TboxOverallChargeStatus {
  PARK_CHARGING = "PARK_CHARGING",
  MOVE_CHARGING = "MOVE_CHARGING",
  UNCHARGED = "UNCHARGED",
  CHARGED = "CHARGED",
}
export enum TboxOverallMode {
  ELECTRIC = "ELECTRIC",
  MIXED = "MIXED",
  FUEL = "FUEL",
}
export enum TboxOverallDcStatus {
  ON = "ON",
  OFF = "OFF",
}
export interface TboxOverall {
  type: TboxInfoType
  status: TboxOverallStatus
  chargeStatus: TboxOverallChargeStatus
  mode: TboxOverallMode
  dcStatus: TboxOverallDcStatus
  speed: number
  mileage: number
  voltage: number
  current: number
  soc: number
  shift: string
  resistance: number
  aptv: number
  brake: number
}
export interface TboxMotor {
  type: TboxInfoType
  count: number
  motors: TboxSingleMotor[]
}
export interface TboxLocation {
  type: TboxInfoType
  state: number
  lng: number
  lat: number
}
export interface TboxExtreme {
  type: TboxInfoType
  maxVoltageSubSysNo: number
  maxVoltageSingNo: number
  maxVoltage: number
  minVoltageSubSysNo: number
  minVoltageSingNo: number
  minVoltage: number
  maxNtcSubSysNo: number
  maxNtcNo: number
  maxNtc: number
  minNtcSubSysNo: number
  minNtcNo: number
  minNtc: number
}
export interface TboxFault {
  type: number
  code: number
  level: number
}
export interface TboxAlarm {
  type: TboxInfoType
  maxLevel: number
  uas: TboxAlarm.Uas
  ressLen: number
  ressList: TboxFault[]
  mortorLen: number
  mortorList: TboxFault[]
  engineLen: number
  engineList: TboxFault[]
  otherLen: number
  otherList: TboxFault[]
  _uasList: TboxFault[]
}
export namespace TboxAlarm {
  export interface Uas {
    ressChargeOver?: boolean
    motorTemp?: boolean
    highVolMuteStatus?: boolean
    motorControlTemp?: boolean
    dcdcStatus?: boolean
    brake?: boolean
    dcdcTemp?: boolean
    insulation?: boolean
    batteryBadConsistency?: boolean
    ressNotMatch?: boolean
    socJump?: boolean
    socOver?: boolean
    batteryLow?: boolean
    batteryOver?: boolean
    socLow?: boolean
    ressVolLow?: boolean
    ressVolOver?: boolean
    batteryTempOver?: boolean
    tempDiff?: boolean
  }
}
export enum TboxDoorStatus {
  CLOSE = "CLOSE",
  OPEN = "OPEN",
  ABNORMAL = "ABNORMAL",
}
export enum TboxHandbrakeStatus {
  ON = "ON",
  OFF = "OFF",
  ABNORMAL = "ABNORMAL",
}
export enum TboxKeyPosition {
  OFF = "OFF",
  ACC = "ACC",
  ON = "ON",
  START = "START",
}
export interface TboxCustom {
  type: TboxInfoType
  airMode: TboxAirMode
  middleDoorStatus: TboxDoorStatus
  frontDoorStatus: TboxDoorStatus
  handbrakeStatus: TboxHandbrakeStatus
  keyPosition: TboxKeyPosition
  dataLen: number
  pressure1: number
  pressure2: number
  batteryVoltage: number
  dcov: number
  dcoc: number
  dcTemp: number
  acTemp: number
  lftp: number
  lftt: number
  rftp: number
  rftt: number
  lr1tp: number
  lr1tt: number
  lr2tp: number
  lr2tt: number
  rr1tp: number
  rr1tt: number
  rr2tp: number
  rr2tt: number
  cv: number
  rc: number
  cp: number
  totalCharge: number
  totalDischarge: number
  instantPower: number
  bpiRes: number
  bniRes: number
  apTemp: number
  motorContTemp: number
  airTemp: number
  insideTemp: number
  outsideTemp: number
}
export interface TboxTenSecondItem {
  accPedal: number
  brake: number
  speed: number
  totalCurrent: number
}
export interface TboxTenSecond {
  datas: TboxTenSecondItem[]
}
export interface TboxBody {
  at: string
  overall: TboxOverall
  motor: TboxMotor
  fuelcell: TboxBody.Fuelcell
  engine: TboxBody.Engine
  location: TboxLocation
  extreme: TboxExtreme
  alarm: TboxAlarm
  ressVoltage: TboxBody.RessVoltage
  ressTemperature: TboxBody.RessTemperature
  customExt: TboxCustom
  tenSeconds: TboxTenSecond
  adas: TboxBody.Adas
  adas2: TboxBody.Adas2
  energy: TboxBody.Energy
  chargingBow: TboxBody.ChargingBow
}
export namespace TboxBody {
  export interface Fuelcell {
  }
  export interface Engine {
  }
  export interface RessVoltage {
  }
  export interface RessTemperature {
  }
  export interface Adas {
  }
  export interface Adas2 {
  }
  export interface Energy {
  }
  export interface ChargingBow {
  }
}
export interface TboxRecord {
  command: TboxCommand
  flag: TboxFlag
  vin: string
  encrypt: string
  length: number
  body: TboxBody
  platform?: string
  receiveAt?: string
  raw: string
}
export interface HelloRequest {
}
export interface CreateBatteryHealthRecordRequest {
  body: CreateBatteryHealthRecordDto
}
export interface ListBatteryHealthRecordsRequest {
  _group?: BatteryHealthRecordGroupField[]
  _limit?: number
  _offset?: number
  _sort?: string
  dataAt_lte?: string
  dataAt_gte?: string
  ns_like?: string
  ns?: string
  batteryNo?: string
  battery?: string
}
export interface GetBatteryHealthRecordRequest {
  batteryHealthRecord: string
}
export interface UpdateBatteryHealthRecordRequest {
  batteryHealthRecord: string
  body: UpdateBatteryHealthRecordDto
}
export interface DeleteBatteryHealthRecordRequest {
  batteryHealthRecord: string
}
export interface AggregateBatteryHealthRecordRequest {
  _group?: BatteryHealthRecordGroupField[]
  _limit?: number
  _offset?: number
  _sort?: string
  dataAt_lte?: string
  dataAt_gte?: string
  ns_like?: string
  ns?: string
  batteryNo?: string
  battery?: string
}
export interface CreateBatteryRequest {
  body: CreateBatteryDto
}
export interface ListBatteriesRequest {
  _limit?: number
  _offset?: number
  _sort?: string
  ns_like?: string
  barcode?: string
  name?: string
  no?: string
  ns?: string
  onsite?: boolean
  source?: string
}
export interface UpsertBatteryRequest {
  body: CreateBatteryDto
}
export interface GetBatteryRequest {
  battery: string
}
export interface UpdateBatteryRequest {
  battery: string
  body: UpdateBatteryDto
}
export interface DeleteBatteryRequest {
  battery: string
}
export interface ListBatteryHealthStatsRequest {
  no?: string
  ns?: string
  ns_like?: string
  source?: string
}
export interface ListBboxRecordsRequest {
  direction?: LokiDirection
  boxCode?: string
  start?: string
  end?: string
  interval?: string
  limit?: number
  text?: string
}
export interface CreateVehicleRequest {
  body: CreateVehicleDto
}
export interface ListVehiclesRequest {
  _group?: VehicleGroupField[]
  _limit?: number
  _offset?: number
  _sort?: string
  _embed?: string[]
  _expand?: string[]
  ns_like?: string
  line?: string
  manufacturer?: string
  model?: string
  modelDetail?: string
  no?: string
  ns?: string
  plate?: string
  source?: string
  stations?: string[]
  stationNo?: string[]
  vin?: string
}
export interface UpsertVehicleRequest {
  body: CreateVehicleDto
}
export interface GetVehicleRequest {
  vehicle: string
}
export interface UpdateVehicleRequest {
  vehicle: string
  body: UpdateVehicleDto
}
export interface DeleteVehicleRequest {
  vehicle: string
}
export interface AggregateVehiclesRequest {
  _group?: VehicleGroupField[]
  _limit?: number
  _offset?: number
  _sort?: string
  _embed?: string[]
  _expand?: string[]
  ns_like?: string
  line?: string
  manufacturer?: string
  model?: string
  modelDetail?: string
  no?: string
  ns?: string
  plate?: string
  source?: string
  stations?: string[]
  stationNo?: string[]
  vin?: string
}
export interface ListVehicleStatesRequest {
  _offset?: number
  _limit?: number
  _sort?: string
  at_lt?: number
  at_gt?: number
  lng?: number
  lat?: number
  radius?: number
  vin?: string[]
  batteryNo?: string[]
  status?: string[]
  ns_like?: string
}
export interface GetVehicleStateRequest {
  key: string
}
export interface UpdateVehicleStateRequest {
  key: string
  body: UpdateVehicleStateDto
}
export interface AggregateVehicleStatesRequest {
  _offset?: number
  _limit?: number
  _sort?: string
  at_lt?: number
  at_gt?: number
  lng?: number
  lat?: number
  radius?: number
  vin?: string[]
  batteryNo?: string[]
  status?: string[]
  ns_like?: string
}
export interface CreateVehicleSnapshotRequest {
  body: CreateVehicleSnapshotDto
}
export interface ListVehicleSnapshotsRequest {
  _group?: VehicleSnapshotGroupField[]
  _offset?: number
  _limit?: number
  _sort?: string
  at_lte?: string
  at_gte?: string
  ns_like?: string
  vin?: string[]
}
export interface GetVehicleSnapshotRequest {
  vehicleSnapshot: string
}
export interface UpdateVehicleSnapshotRequest {
  vehicleSnapshot: string
  body: UpdateVehicleSnapshotDto
}
export interface DeleteVehicleSnapshotRequest {
  vehicleSnapshot: string
}
export interface AggregateVehicleSnapshotRequest {
  _group?: VehicleSnapshotGroupField[]
  _offset?: number
  _limit?: number
  _sort?: string
  at_lte?: string
  at_gte?: string
  ns_like?: string
  vin?: string[]
}
export interface CreateStatusSnapshotRequest {
  body: CreateStatusSnapshotDto
}
export interface ListStatusSnapshotsRequest {
  _offset?: number
  _limit?: number
  _sort?: string
  at_lte?: string
  at_gte?: string
}
export interface GetStatusSnapshotRequest {
  statusSnapshot: string
}
export interface UpdateStatusSnapshotRequest {
  statusSnapshot: string
  body: UpdateStatusSnapshotDto
}
export interface DeleteStatusSnapshotRequest {
  statusSnapshot: string
}
export interface CreateDeviceFaultRecordRequest {
  body: CreateFaultRecordDto
}
export interface ListDeviceFaultRecordsRequest {
  category?: FaultCategory
  status?: FaultRecordStatus
  _limit?: number
  _offset?: number
  _sort?: string
  ns_like?: string
  name_like?: string
  brokenAt_gte?: string
  brokenAt_lte?: string
  code?: string[]
  count_gte?: number
  count_lte?: number
  level_gte?: number
  level_lte?: number
  recoverAt_gte?: string
  recoverAt_lte?: string
  "device.id"?: string
  "device.no"?: string
  "device.ns"?: string
  "device.manufacturer"?: string
  "device.model"?: string
  "device.type"?: string
  level?: number
  line?: string
  mute?: boolean
  name?: string
  ns?: string
  plate?: string
  sn?: string
  source?: string
  station?: string
  stationNo?: string
  type?: string
  vin?: string
}
export interface AggregateFaultRecordsRequest {
  category?: FaultCategory
  status?: FaultRecordStatus
  _group?: FaultRecordGroupField[]
  _limit?: number
  _offset?: number
  _sort?: string
  ns_like?: string
  name_like?: string
  brokenAt_gte?: string
  brokenAt_lte?: string
  code?: string[]
  count_gte?: number
  count_lte?: number
  level_gte?: number
  level_lte?: number
  recoverAt_gte?: string
  recoverAt_lte?: string
  "device.id"?: string
  "device.no"?: string
  "device.ns"?: string
  "device.manufacturer"?: string
  "device.model"?: string
  "device.type"?: string
  level?: number
  line?: string
  mute?: boolean
  name?: string
  ns?: string
  plate?: string
  sn?: string
  source?: string
  station?: string
  stationNo?: string
  type?: string
  vin?: string
}
export interface GetDeviceFaultRecordRequest {
  faultRecord: string
}
export interface UpdateDeviceFaultRecordRequest {
  faultRecord: string
  body: UpdateFaultRecordDto
}
export interface DeleteDeviceFaultRecordRequest {
  faultRecord: string
}
export interface CreateBillRequest {
  body: CreateBillDto
}
export interface ListBillsRequest {
  status?: BillStatus
  _limit?: number
  _offset?: number
  _sort?: string
  ns_like?: string
  name_like?: string
  startAt_lte?: string
  startAt_gte?: string
  endAt_lte?: string
  endAt_gte?: string
  name?: string
  ns?: string
}
export interface GetBillRequest {
  bill: string
}
export interface UpdateBillRequest {
  bill: string
  body: UpdateBillDto
}
export interface DeleteBillRequest {
  bill: string
}
export interface CheckoutBillRequest {
  body: CheckoutDto
}
export interface CreateOrderRequest {
  body: CreateOrderDto
}
export interface ListOrdersRequest {
  type?: OrderType
  _group?: OrderGroupField[]
  _count?: OrderCountableField[]
  _limit?: number
  _offset?: number
  _sort?: string
  _embed?: string[]
  _expand?: string[]
  ns_like?: string
  abnormal?: boolean
  batteryNs_like?: string
  chargeStartAt_lte?: string
  chargeStartAt_gte?: string
  chargeEndAt_lte?: string
  chargeEndAt_gte?: string
  line_like?: string
  "stopReason.name_like"?: string
  "stopReason.level"?: number
  swapStartAt_lte?: string
  swapStartAt_gte?: string
  swapEndAt_lte?: string
  swapEndAt_gte?: string
  at_lte?: string
  at_gte?: string
  account?: string
  connectorNo?: string
  chargeSn?: string
  driver?: string
  driverName?: string
  driverPhone?: string
  grabBatteryNo?: string
  isFull?: boolean
  isReissue?: boolean
  line?: string
  model?: string
  ns?: string
  plate?: string
  pushBatteryNo?: string
  source?: string
  station?: string
  stationNo?: string
  stopAbnormal?: boolean
  swapSn?: string
  vin?: string
}
export interface AggregateOrdersRequest {
  type?: OrderType
  _group?: OrderGroupField[]
  _count?: OrderCountableField[]
  _limit?: number
  _offset?: number
  _sort?: string
  _embed?: string[]
  _expand?: string[]
  ns_like?: string
  abnormal?: boolean
  batteryNs_like?: string
  chargeStartAt_lte?: string
  chargeStartAt_gte?: string
  chargeEndAt_lte?: string
  chargeEndAt_gte?: string
  line_like?: string
  "stopReason.name_like"?: string
  "stopReason.level"?: number
  swapStartAt_lte?: string
  swapStartAt_gte?: string
  swapEndAt_lte?: string
  swapEndAt_gte?: string
  at_lte?: string
  at_gte?: string
  account?: string
  connectorNo?: string
  chargeSn?: string
  driver?: string
  driverName?: string
  driverPhone?: string
  grabBatteryNo?: string
  isFull?: boolean
  isReissue?: boolean
  line?: string
  model?: string
  ns?: string
  plate?: string
  pushBatteryNo?: string
  source?: string
  station?: string
  stationNo?: string
  stopAbnormal?: boolean
  swapSn?: string
  vin?: string
}
export interface GetOrderRequest {
  order: string
}
export interface UpdateOrderRequest {
  order: string
  body: UpdateOrderDto
}
export interface DeleteOrderRequest {
  order: string
}
export interface UpsertOrderRequest {
  body: CreateOrderDto
}
export interface CreateRateTemplateRequest {
  body: CreateRateTemplateDto
}
export interface ListRateTemplatesRequest {
  type?: RateTemplateType
  _limit?: number
  _offset?: number
  _sort?: string
  name?: string
}
export interface GetRateTemplateRequest {
  rateTemplate: string
}
export interface UpdateRateTemplateRequest {
  rateTemplate: string
  body: UpdateRateTemplateDto
}
export interface DeleteRateTemplateRequest {
  rateTemplate: string
}
export interface CreateStationRequest {
  body: CreateStationDto
}
export interface ListStationsRequest {
  type?: StationType
  _count?: ListStationsCountableField[]
  _limit?: number
  _offset?: number
  _sort?: string
  ns_like?: string
  name_like?: string
  name?: string
  ns?: string
  no?: string[]
  source?: string[]
}
export interface GetStationRequest {
  station: string
}
export interface UpdateStationRequest {
  station: string
  body: UpdateStationDto
}
export interface DeleteStationRequest {
  station: string
}
export interface GetStationReserveSlotsRequest {
  station: string
}
export interface GetJiuXingStationListRequest {
}
export interface GetStationMonitorRequest {
  station: string
}
export interface StartPlayRequest {
  deviceid: string
}
export interface StartPlaybackRequest {
  body: PlaybackMonitorDto
}
export interface StopPlaybackRequest {
  body: StopPlaybackMonitorDto
}
export interface ListStationStatesRequest {
  no?: string[]
  name?: string
}
export interface GetStationStateRequest {
  stationNo: string
}
export interface UpdateStationStateRequest {
  stationNo: string
  body: UpdateStationStateDto
}
export interface CreatePileRequest {
  body: CreatePileDto
}
export interface ListPilesRequest {
  type?: PileType
  _limit?: number
  _offset?: number
  _sort?: string
  ns_like?: string
  name_like?: string
  name?: string
  no?: string
  ns?: string
  source?: string
  station?: string
  stationNo?: string
}
export interface GetPileRequest {
  pile: string
}
export interface UpdatePileRequest {
  pile: string
  body: UpdatePileDto
}
export interface DeletePileRequest {
  pile: string
}
export interface CreateConnectorRequest {
  body: CreateConnectorDto
}
export interface ListConnectorsRequest {
  type?: ConnectorType
  status?: ConnectorStatus
  _group?: ConnectorGroupField[]
  _limit?: number
  _offset?: number
  _sort?: string
  ns_like?: string
  name_like?: string
  name?: string
  no?: string
  ns?: string
  pileNo?: string
  source?: string
  station?: string
  stationNo?: string
}
export interface GetConnectorRequest {
  connector: string
}
export interface UpdateConnectorRequest {
  connector: string
  body: UpdateConnectorDto
}
export interface DeleteConnectorRequest {
  connector: string
}
export interface GetConnectorChargeStateRequest {
  connector: string
}
export interface UpdateConnectorByNoRequest {
  body: UpdateConnectorDto
}
export interface CreateCompanyRequest {
  body: CreateCompanyDto
}
export interface ListCompaniesRequest {
  type?: NamespaceLabel
  _count?: ListCompanyCountableField[]
  _limit?: number
  _offset?: number
  _sort?: string
  id_like?: string
  ns_like?: string
  name_like?: string
  name?: string
  ns?: string
}
export interface GetCompanyRequest {
  _count?: ListCompanyCountableField[]
  company: string
}
export interface UpdateCompanyRequest {
  company: string
  body: UpdateCompanyDto
}
export interface DeleteCompanyRequest {
  company: string
}
export interface CreateDriverRequest {
  body: CreateDriverDto
}
export interface ListDriversRequest {
  _limit?: number
  _offset?: number
  _sort?: string
  ns_like?: string
  name_like?: string
  ns?: string
  phone?: string
}
export interface GetDriverRequest {
  driver: string
}
export interface UpdateDriverRequest {
  driver: string
  body: UpdateDriverDto
}
export interface DeleteDriverRequest {
  driver: string
}
export interface CreateMaintainerRequest {
  body: CreateMaintainerDto
}
export interface ListMaintainersRequest {
  _limit?: number
  _offset?: number
  _sort?: string
  ns_like?: string
  name_like?: string
  ns?: string
  phone?: string
}
export interface GetMaintainerRequest {
  maintainer: string
}
export interface UpdateMaintainerRequest {
  maintainer: string
  body: UpdateMaintainerDto
}
export interface DeleteMaintainerRequest {
  maintainer: string
}
export interface CreateChargeRecordRequest {
  body: CreateChargeRecordDto
}
export interface ListChargeRecordsRequest {
  _limit?: number
  _offset?: number
  _sort?: string
  ns_like?: string
  startAt_lte?: string
  startAt_gte?: string
  endAt_lte?: string
  endAt_gte?: string
  batteryNo?: string
  chargerNo?: string
  ns?: string
  plate?: string
  sn?: string
  source?: string
  station?: string
  swapSn?: string
  vin?: string
}
export interface GetChargeRecordRequest {
  chargeRecord: string
}
export interface UpdateChargeRecordRequest {
  chargeRecord: string
  body: UpdateChargeRecordDto
}
export interface DeleteChargeRecordRequest {
  chargeRecord: string
}
export interface CreateOperationRequest {
  body: CreateOperationDto
}
export interface ListOperationsRequest {
  _limit?: number
  _offset?: number
  _sort?: string
  ns_like?: string
  createAt_gt?: string
  createAt_lt?: string
  domain?: string
  ns?: string
  name?: string
  user?: string
}
export interface GetOperationRequest {
  operation: string
}
export interface UpdateOperationRequest {
  operation: string
  body: UpdateOperationDto
}
export interface DeleteOperationRequest {
  operation: string
}
export interface CreatePaperRequest {
  body: CreatePaperDto
}
export interface ListPapersRequest {
  _limit?: number
  _offset?: number
  _sort?: string
  labels?: string[]
  sheet?: string
}
export interface GetPaperRequest {
  paper: string
}
export interface UpdatePaperRequest {
  paper: string
  body: UpdatePaperDto
}
export interface DeletePaperRequest {
  paper: string
}
export interface CreateReservationRequest {
  body: CreateReservationDto
}
export interface ListReservationsRequest {
  status?: ReservationStatus
  _group?: ReservationGroupField[]
  _limit?: number
  _offset?: number
  _sort?: string
  ns_like?: string
  reserveTime_gte?: string
  reserveTime_lte?: string
  ns?: string
  driver?: string
  driverName?: string
  driverPhone?: string
  plate?: string
  station?: string
  swapSn?: string
}
export interface CancelReservationRequest {
  reservation: string
}
export interface AggregateReservationsRequest {
  status?: ReservationStatus
  _group?: ReservationGroupField[]
  _limit?: number
  _offset?: number
  _sort?: string
  ns_like?: string
  reserveTime_gte?: string
  reserveTime_lte?: string
  ns?: string
  driver?: string
  driverName?: string
  driverPhone?: string
  plate?: string
  station?: string
  swapSn?: string
}
export interface GetReservationRequest {
  reservation: string
}
export interface UpdateReservationRequest {
  reservation: string
  body: UpdateReservationDto
}
export interface DeleteReservationRequest {
  reservation: string
}
export interface CreateSheetRequest {
  body: CreateSheetDto
}
export interface ListSheetsRequest {
  _limit?: number
  _offset?: number
  _sort?: string
  title_like?: string
  isTemplate?: boolean
  labels?: string[]
}
export interface GetSheetRequest {
  sheet: string
}
export interface UpdateSheetRequest {
  sheet: string
  body: UpdateSheetDto
}
export interface DeleteSheetRequest {
  sheet: string
}
export interface CreateStaffShiftRequest {
  body: CreateStaffShiftDto
}
export interface ListStaffShiftsRequest {
  status?: StaffShiftStatus
  _expand?: StaffShiftExpandField[]
  _group?: StaffShiftGroupField[]
  _limit?: number
  _offset?: number
  _sort?: string
  ns_like?: string
  submitAt_lte?: string
  submitAt_gte?: string
  abnormal?: boolean
  submitBy?: string
  successors?: string[]
  station?: string
  ns?: string
}
export interface AggregateStaffShiftsRequest {
  status?: StaffShiftStatus
  _expand?: StaffShiftExpandField[]
  _group?: StaffShiftGroupField[]
  _limit?: number
  _offset?: number
  _sort?: string
  ns_like?: string
  submitAt_lte?: string
  submitAt_gte?: string
  abnormal?: boolean
  submitBy?: string
  successors?: string[]
  station?: string
  ns?: string
}
export interface GetStaffShiftRequest {
  staffShift: string
}
export interface UpdateStaffShiftRequest {
  staffShift: string
  body: UpdateStaffShiftDto
}
export interface DeleteStaffShiftRequest {
  staffShift: string
}
export interface ConfirmStaffShiftRequest {
  staffShift: string
  body: ConfirmStaffShiftDto
}
export interface CreateSwapRecordRequest {
  body: CreateSwapRecordDto
}
export interface ListSwapRecordsRequest {
  _limit?: number
  _offset?: number
  _sort?: string
  ns_like?: string
  startAt_lte?: string
  startAt_gte?: string
  endAt_lte?: string
  endAt_gte?: string
  ns?: string
  chargeSn?: string[]
  driver?: string
  driverName?: string
  driverPhone?: string
  grabBatteryNo?: string
  plate?: string
  pushBatteryNo?: string
  sn?: string
  source?: string
  station?: string
  vin?: string
}
export interface GetSwapRecordRequest {
  swapRecord: string
}
export interface UpdateSwapRecordRequest {
  swapRecord: string
  body: UpdateSwapRecordDto
}
export interface DeleteSwapRecordRequest {
  swapRecord: string
}
export interface CreateWastageRequest {
  body: CreateWastageDto
}
export interface ListWastagesRequest {
  _limit?: number
  _offset?: number
  _sort?: string
  month_lte?: string
  month_gte?: string
  ns_like?: string
  ns?: string
  station?: string
  month?: string
}
export interface GetWastageRequest {
  wastage: string
}
export interface UpdateWastageRequest {
  wastage: string
  body: UpdateWastageDto
}
export interface DeleteWastageRequest {
  wastage: string
}
export interface BatchCreateWorkOrderRequest {
  body: CreateWorkOrderDto
}
export interface CreateWorkOrderRequest {
  body: CreateWorkOrderDto
}
export interface ListWorkOrdersRequest {
  status?: WorkOrderStatus
  type?: WorkOrderType
  _group?: WorkOrderGroupField[]
  _limit?: number
  _offset?: number
  _sort?: string
  id?: string
  assignees?: string
  createAt_lte?: string
  createAt_gte?: string
  submitAt_lte?: string
  submitAt_gte?: string
  "devices.id"?: string
  "devices.no"?: string
  "devices.ns"?: string
  ns_like?: string
  ns?: string
  submitBy?: string
}
export interface AggregateWorkOrdersRequest {
  status?: WorkOrderStatus
  type?: WorkOrderType
  _group?: WorkOrderGroupField[]
  _limit?: number
  _offset?: number
  _sort?: string
  id?: string
  assignees?: string
  createAt_lte?: string
  createAt_gte?: string
  submitAt_lte?: string
  submitAt_gte?: string
  "devices.id"?: string
  "devices.no"?: string
  "devices.ns"?: string
  ns_like?: string
  ns?: string
  submitBy?: string
}
export interface GetWorkOrderRequest {
  workOrder: string
}
export interface UpdateWorkOrderRequest {
  workOrder: string
  body: UpdateWorkOrderDto
}
export interface DeleteWorkOrderRequest {
  workOrder: string
}
export interface CloseWorkOrderRequest {
  workOrder: string
}
export interface SubmitWorkOrderRequest {
  workOrder: string
  body: SubmitWorkOrderDto
}
export interface HandleWorkOrderRequest {
  workOrder: string
  body: HandleWorkOrderDto
}
export interface ListTboxRecordsRequest {
  direction?: LokiDirection
  vin?: string
  start?: string
  end?: string
  interval?: string
  limit?: number
  text?: string
}