// Generated by openapi-ts-gen. DO NOT EDIT
/* eslint-disable */
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import * as schemas from "./schemas";

export class MekongAPIClient {
  public client: AxiosInstance;
  constructor(client: AxiosInstance) {
    if (!client.defaults.baseURL) {
      throw new Error("client's baseURL MUST specified");
    }
    this.client = client;
  }
  hello = (req: schemas.HelloRequest, config: AxiosRequestConfig = {}): Response<schemas.HealthCheckResult> => {
    return this.client.request({
      ...config,
      url: `/hello`, 
      method: "get", 
    })
  }
  createBatteryHealthRecord = (req: schemas.CreateBatteryHealthRecordRequest, config: AxiosRequestConfig = {}): Response<schemas.BatteryHealthRecord> => {
    return this.client.request({
      ...config,
      url: `/battery-health-records`, 
      method: "post", 
      data: req.body
    })
  }
  listBatteryHealthRecords = (req: schemas.ListBatteryHealthRecordsRequest, config: AxiosRequestConfig = {}): Response<schemas.BatteryHealthRecord[]> => {
    return this.client.request({
      ...config,
      url: `/battery-health-records`, 
      method: "get", 
      params: pick(req, ["_group", "_limit", "_offset", "_sort", "dataAt_lte", "dataAt_gte", "ns_like", "ns", "batteryNo", "battery"])
    })
  }
  getBatteryHealthRecord = (req: schemas.GetBatteryHealthRecordRequest, config: AxiosRequestConfig = {}): Response<schemas.BatteryHealthRecord> => {
    checkPathParams("getBatteryHealthRecord", req, ["batteryHealthRecord"])
    return this.client.request({
      ...config,
      url: `/battery-health-records/${req.batteryHealthRecord}`, 
      method: "get", 
    })
  }
  updateBatteryHealthRecord = (req: schemas.UpdateBatteryHealthRecordRequest, config: AxiosRequestConfig = {}): Response<schemas.BatteryHealthRecord> => {
    checkPathParams("updateBatteryHealthRecord", req, ["batteryHealthRecord"])
    return this.client.request({
      ...config,
      url: `/battery-health-records/${req.batteryHealthRecord}`, 
      method: "patch", 
      data: req.body
    })
  }
  deleteBatteryHealthRecord = (req: schemas.DeleteBatteryHealthRecordRequest, config: AxiosRequestConfig = {}): Response<void> => {
    checkPathParams("deleteBatteryHealthRecord", req, ["batteryHealthRecord"])
    return this.client.request({
      ...config,
      url: `/battery-health-records/${req.batteryHealthRecord}`, 
      method: "delete", 
    })
  }
  aggregateBatteryHealthRecord = (req: schemas.AggregateBatteryHealthRecordRequest, config: AxiosRequestConfig = {}): Response<schemas.BatteryHealthRecordAvgResult[]> => {
    return this.client.request({
      ...config,
      url: `/battery-health-records/aggregate`, 
      method: "post", 
      params: pick(req, ["_group", "_limit", "_offset", "_sort", "dataAt_lte", "dataAt_gte", "ns_like", "ns", "batteryNo", "battery"])
    })
  }
  createBattery = (req: schemas.CreateBatteryRequest, config: AxiosRequestConfig = {}): Response<schemas.Battery> => {
    return this.client.request({
      ...config,
      url: `/batteries`, 
      method: "post", 
      data: req.body
    })
  }
  listBatteries = (req: schemas.ListBatteriesRequest, config: AxiosRequestConfig = {}): Response<schemas.Battery[]> => {
    return this.client.request({
      ...config,
      url: `/batteries`, 
      method: "get", 
      params: pick(req, ["_limit", "_offset", "_sort", "ns_like", "barcode", "name", "no", "ns", "onsite", "source"])
    })
  }
  upsertBattery = (req: schemas.UpsertBatteryRequest, config: AxiosRequestConfig = {}): Response<schemas.Battery> => {
    return this.client.request({
      ...config,
      url: `/batteries/upsert`, 
      method: "post", 
      data: req.body
    })
  }
  getBattery = (req: schemas.GetBatteryRequest, config: AxiosRequestConfig = {}): Response<schemas.Battery> => {
    checkPathParams("getBattery", req, ["battery"])
    return this.client.request({
      ...config,
      url: `/batteries/${req.battery}`, 
      method: "get", 
    })
  }
  updateBattery = (req: schemas.UpdateBatteryRequest, config: AxiosRequestConfig = {}): Response<schemas.Battery> => {
    checkPathParams("updateBattery", req, ["battery"])
    return this.client.request({
      ...config,
      url: `/batteries/${req.battery}`, 
      method: "patch", 
      data: req.body
    })
  }
  deleteBattery = (req: schemas.DeleteBatteryRequest, config: AxiosRequestConfig = {}): Response<void> => {
    checkPathParams("deleteBattery", req, ["battery"])
    return this.client.request({
      ...config,
      url: `/batteries/${req.battery}`, 
      method: "delete", 
    })
  }
  listBatteryHealthStats = (req: schemas.ListBatteryHealthStatsRequest, config: AxiosRequestConfig = {}): Response<schemas.BatteryHealthStats[]> => {
    return this.client.request({
      ...config,
      url: `/batteryHealthStats`, 
      method: "get", 
      params: pick(req, ["no", "ns", "ns_like", "source"])
    })
  }
  listBboxRecords = (req: schemas.ListBboxRecordsRequest, config: AxiosRequestConfig = {}): Response<schemas.BboxRecord[]> => {
    return this.client.request({
      ...config,
      url: `/bbox-records`, 
      method: "get", 
      params: pick(req, ["direction", "boxCode", "start", "end", "interval", "limit", "text"])
    })
  }
  createVehicle = (req: schemas.CreateVehicleRequest, config: AxiosRequestConfig = {}): Response<schemas.Vehicle> => {
    return this.client.request({
      ...config,
      url: `/vehicles`, 
      method: "post", 
      data: req.body
    })
  }
  listVehicles = (req: schemas.ListVehiclesRequest, config: AxiosRequestConfig = {}): Response<schemas.Vehicle[]> => {
    return this.client.request({
      ...config,
      url: `/vehicles`, 
      method: "get", 
      params: pick(req, ["_group", "_limit", "_offset", "_sort", "_embed", "_expand", "ns_like", "line", "manufacturer", "model", "modelDetail", "no", "ns", "plate", "source", "stations", "stationNo", "vin"])
    })
  }
  upsertVehicle = (req: schemas.UpsertVehicleRequest, config: AxiosRequestConfig = {}): Response<schemas.Vehicle> => {
    return this.client.request({
      ...config,
      url: `/vehicles/upsert`, 
      method: "post", 
      data: req.body
    })
  }
  getVehicle = (req: schemas.GetVehicleRequest, config: AxiosRequestConfig = {}): Response<schemas.Vehicle> => {
    checkPathParams("getVehicle", req, ["vehicle"])
    return this.client.request({
      ...config,
      url: `/vehicles/${req.vehicle}`, 
      method: "get", 
    })
  }
  updateVehicle = (req: schemas.UpdateVehicleRequest, config: AxiosRequestConfig = {}): Response<schemas.Vehicle> => {
    checkPathParams("updateVehicle", req, ["vehicle"])
    return this.client.request({
      ...config,
      url: `/vehicles/${req.vehicle}`, 
      method: "patch", 
      data: req.body
    })
  }
  deleteVehicle = (req: schemas.DeleteVehicleRequest, config: AxiosRequestConfig = {}): Response<void> => {
    checkPathParams("deleteVehicle", req, ["vehicle"])
    return this.client.request({
      ...config,
      url: `/vehicles/${req.vehicle}`, 
      method: "delete", 
    })
  }
  aggregateVehicles = (req: schemas.AggregateVehiclesRequest, config: AxiosRequestConfig = {}): Response<schemas.VehicleAggregateResult[]> => {
    return this.client.request({
      ...config,
      url: `/vehicles/aggregate`, 
      method: "post", 
      params: pick(req, ["_group", "_limit", "_offset", "_sort", "_embed", "_expand", "ns_like", "line", "manufacturer", "model", "modelDetail", "no", "ns", "plate", "source", "stations", "stationNo", "vin"])
    })
  }
  listVehicleStates = (req: schemas.ListVehicleStatesRequest, config: AxiosRequestConfig = {}): Response<schemas.VehicleState[]> => {
    return this.client.request({
      ...config,
      url: `/vehicle-states`, 
      method: "get", 
      params: pick(req, ["_offset", "_limit", "_sort", "at_lt", "at_gt", "lng", "lat", "radius", "vin", "batteryNo", "status", "ns_like"])
    })
  }
  getVehicleState = (req: schemas.GetVehicleStateRequest, config: AxiosRequestConfig = {}): Response<schemas.VehicleState> => {
    checkPathParams("getVehicleState", req, ["key"])
    return this.client.request({
      ...config,
      url: `/vehicle-states/${req.key}`, 
      method: "get", 
    })
  }
  updateVehicleState = (req: schemas.UpdateVehicleStateRequest, config: AxiosRequestConfig = {}): Response<schemas.VehicleState> => {
    checkPathParams("updateVehicleState", req, ["key"])
    return this.client.request({
      ...config,
      url: `/vehicle-states/${req.key}`, 
      method: "patch", 
      data: req.body
    })
  }
  aggregateVehicleStates = (req: schemas.AggregateVehicleStatesRequest, config: AxiosRequestConfig = {}): Response<schemas.VehicleStateAggResult[]> => {
    return this.client.request({
      ...config,
      url: `/vehicle-states/aggregate`, 
      method: "post", 
      params: pick(req, ["_offset", "_limit", "_sort", "at_lt", "at_gt", "lng", "lat", "radius", "vin", "batteryNo", "status", "ns_like"])
    })
  }
  createVehicleSnapshot = (req: schemas.CreateVehicleSnapshotRequest, config: AxiosRequestConfig = {}): Response<schemas.VehicleSnapshot> => {
    return this.client.request({
      ...config,
      url: `/vehicle-snapshots`, 
      method: "post", 
      data: req.body
    })
  }
  listVehicleSnapshots = (req: schemas.ListVehicleSnapshotsRequest, config: AxiosRequestConfig = {}): Response<schemas.VehicleSnapshot[]> => {
    return this.client.request({
      ...config,
      url: `/vehicle-snapshots`, 
      method: "get", 
      params: pick(req, ["_group", "_offset", "_limit", "_sort", "at_lte", "at_gte", "ns_like", "vin"])
    })
  }
  getVehicleSnapshot = (req: schemas.GetVehicleSnapshotRequest, config: AxiosRequestConfig = {}): Response<schemas.VehicleSnapshot> => {
    checkPathParams("getVehicleSnapshot", req, ["vehicleSnapshot"])
    return this.client.request({
      ...config,
      url: `/vehicle-snapshots/${req.vehicleSnapshot}`, 
      method: "get", 
    })
  }
  updateVehicleSnapshot = (req: schemas.UpdateVehicleSnapshotRequest, config: AxiosRequestConfig = {}): Response<schemas.VehicleSnapshot> => {
    checkPathParams("updateVehicleSnapshot", req, ["vehicleSnapshot"])
    return this.client.request({
      ...config,
      url: `/vehicle-snapshots/${req.vehicleSnapshot}`, 
      method: "patch", 
      data: req.body
    })
  }
  deleteVehicleSnapshot = (req: schemas.DeleteVehicleSnapshotRequest, config: AxiosRequestConfig = {}): Response<void> => {
    checkPathParams("deleteVehicleSnapshot", req, ["vehicleSnapshot"])
    return this.client.request({
      ...config,
      url: `/vehicle-snapshots/${req.vehicleSnapshot}`, 
      method: "delete", 
    })
  }
  aggregateVehicleSnapshot = (req: schemas.AggregateVehicleSnapshotRequest, config: AxiosRequestConfig = {}): Response<schemas.VehicleSnapshotAggregateResult[]> => {
    return this.client.request({
      ...config,
      url: `/vehicle-snapshots/aggregate`, 
      method: "post", 
      params: pick(req, ["_group", "_offset", "_limit", "_sort", "at_lte", "at_gte", "ns_like", "vin"])
    })
  }
  createStatusSnapshot = (req: schemas.CreateStatusSnapshotRequest, config: AxiosRequestConfig = {}): Response<schemas.StatusSnapshot> => {
    return this.client.request({
      ...config,
      url: `/status-snapshots`, 
      method: "post", 
      data: req.body
    })
  }
  listStatusSnapshots = (req: schemas.ListStatusSnapshotsRequest, config: AxiosRequestConfig = {}): Response<schemas.StatusSnapshot[]> => {
    return this.client.request({
      ...config,
      url: `/status-snapshots`, 
      method: "get", 
      params: pick(req, ["_offset", "_limit", "_sort", "at_lte", "at_gte"])
    })
  }
  getStatusSnapshot = (req: schemas.GetStatusSnapshotRequest, config: AxiosRequestConfig = {}): Response<schemas.StatusSnapshot> => {
    checkPathParams("getStatusSnapshot", req, ["statusSnapshot"])
    return this.client.request({
      ...config,
      url: `/status-snapshots/${req.statusSnapshot}`, 
      method: "get", 
    })
  }
  updateStatusSnapshot = (req: schemas.UpdateStatusSnapshotRequest, config: AxiosRequestConfig = {}): Response<schemas.StatusSnapshot> => {
    checkPathParams("updateStatusSnapshot", req, ["statusSnapshot"])
    return this.client.request({
      ...config,
      url: `/status-snapshots/${req.statusSnapshot}`, 
      method: "patch", 
      data: req.body
    })
  }
  deleteStatusSnapshot = (req: schemas.DeleteStatusSnapshotRequest, config: AxiosRequestConfig = {}): Response<void> => {
    checkPathParams("deleteStatusSnapshot", req, ["statusSnapshot"])
    return this.client.request({
      ...config,
      url: `/status-snapshots/${req.statusSnapshot}`, 
      method: "delete", 
    })
  }
  createDeviceFaultRecord = (req: schemas.CreateDeviceFaultRecordRequest, config: AxiosRequestConfig = {}): Response<schemas.FaultRecord> => {
    return this.client.request({
      ...config,
      url: `/fault-records`, 
      method: "post", 
      data: req.body
    })
  }
  listDeviceFaultRecords = (req: schemas.ListDeviceFaultRecordsRequest, config: AxiosRequestConfig = {}): Response<schemas.FaultRecord[]> => {
    return this.client.request({
      ...config,
      url: `/fault-records`, 
      method: "get", 
      params: pick(req, ["category", "status", "_limit", "_offset", "_sort", "ns_like", "name_like", "brokenAt_gte", "brokenAt_lte", "code", "count_gte", "count_lte", "level_gte", "level_lte", "recoverAt_gte", "recoverAt_lte", "device.id", "device.no", "device.ns", "device.manufacturer", "device.model", "device.type", "level", "line", "mute", "name", "ns", "plate", "sn", "source", "station", "stationNo", "type", "vin"])
    })
  }
  aggregateFaultRecords = (req: schemas.AggregateFaultRecordsRequest, config: AxiosRequestConfig = {}): Response<schemas.FaultRecordAggregateResult[]> => {
    return this.client.request({
      ...config,
      url: `/fault-records/aggregate`, 
      method: "post", 
      params: pick(req, ["category", "status", "_group", "_limit", "_offset", "_sort", "ns_like", "name_like", "brokenAt_gte", "brokenAt_lte", "code", "count_gte", "count_lte", "level_gte", "level_lte", "recoverAt_gte", "recoverAt_lte", "device.id", "device.no", "device.ns", "device.manufacturer", "device.model", "device.type", "level", "line", "mute", "name", "ns", "plate", "sn", "source", "station", "stationNo", "type", "vin"])
    })
  }
  getDeviceFaultRecord = (req: schemas.GetDeviceFaultRecordRequest, config: AxiosRequestConfig = {}): Response<schemas.FaultRecord> => {
    checkPathParams("getDeviceFaultRecord", req, ["faultRecord"])
    return this.client.request({
      ...config,
      url: `/fault-records/${req.faultRecord}`, 
      method: "get", 
    })
  }
  updateDeviceFaultRecord = (req: schemas.UpdateDeviceFaultRecordRequest, config: AxiosRequestConfig = {}): Response<schemas.FaultRecord> => {
    checkPathParams("updateDeviceFaultRecord", req, ["faultRecord"])
    return this.client.request({
      ...config,
      url: `/fault-records/${req.faultRecord}`, 
      method: "patch", 
      data: req.body
    })
  }
  deleteDeviceFaultRecord = (req: schemas.DeleteDeviceFaultRecordRequest, config: AxiosRequestConfig = {}): Response<void> => {
    checkPathParams("deleteDeviceFaultRecord", req, ["faultRecord"])
    return this.client.request({
      ...config,
      url: `/fault-records/${req.faultRecord}`, 
      method: "delete", 
    })
  }
  createBill = (req: schemas.CreateBillRequest, config: AxiosRequestConfig = {}): Response<schemas.Bill> => {
    return this.client.request({
      ...config,
      url: `/bills`, 
      method: "post", 
      data: req.body
    })
  }
  listBills = (req: schemas.ListBillsRequest, config: AxiosRequestConfig = {}): Response<schemas.Bill[]> => {
    return this.client.request({
      ...config,
      url: `/bills`, 
      method: "get", 
      params: pick(req, ["status", "_limit", "_offset", "_sort", "ns_like", "name_like", "startAt_lte", "startAt_gte", "endAt_lte", "endAt_gte", "name", "ns"])
    })
  }
  getBill = (req: schemas.GetBillRequest, config: AxiosRequestConfig = {}): Response<schemas.Bill> => {
    checkPathParams("getBill", req, ["bill"])
    return this.client.request({
      ...config,
      url: `/bills/${req.bill}`, 
      method: "get", 
    })
  }
  updateBill = (req: schemas.UpdateBillRequest, config: AxiosRequestConfig = {}): Response<schemas.Bill> => {
    checkPathParams("updateBill", req, ["bill"])
    return this.client.request({
      ...config,
      url: `/bills/${req.bill}`, 
      method: "patch", 
      data: req.body
    })
  }
  deleteBill = (req: schemas.DeleteBillRequest, config: AxiosRequestConfig = {}): Response<void> => {
    checkPathParams("deleteBill", req, ["bill"])
    return this.client.request({
      ...config,
      url: `/bills/${req.bill}`, 
      method: "delete", 
    })
  }
  checkoutBill = (req: schemas.CheckoutBillRequest, config: AxiosRequestConfig = {}): Response<schemas.Bill[]> => {
    return this.client.request({
      ...config,
      url: `/bills/checkout`, 
      method: "post", 
      data: req.body
    })
  }
  createOrder = (req: schemas.CreateOrderRequest, config: AxiosRequestConfig = {}): Response<schemas.Order> => {
    return this.client.request({
      ...config,
      url: `/orders`, 
      method: "post", 
      data: req.body
    })
  }
  listOrders = (req: schemas.ListOrdersRequest, config: AxiosRequestConfig = {}): Response<schemas.Order[]> => {
    return this.client.request({
      ...config,
      url: `/orders`, 
      method: "get", 
      params: pick(req, ["type", "_group", "_count", "_limit", "_offset", "_sort", "_embed", "_expand", "ns_like", "abnormal", "batteryNs_like", "chargeStartAt_lte", "chargeStartAt_gte", "chargeEndAt_lte", "chargeEndAt_gte", "line_like", "stopReason.name_like", "stopReason.level", "swapStartAt_lte", "swapStartAt_gte", "swapEndAt_lte", "swapEndAt_gte", "at_lte", "at_gte", "account", "connectorNo", "chargeSn", "driver", "driverName", "driverPhone", "grabBatteryNo", "isFull", "isReissue", "line", "model", "ns", "plate", "pushBatteryNo", "source", "station", "stationNo", "stopAbnormal", "swapSn", "vin"])
    })
  }
  aggregateOrders = (req: schemas.AggregateOrdersRequest, config: AxiosRequestConfig = {}): Response<schemas.OrderAggregateResult[]> => {
    return this.client.request({
      ...config,
      url: `/orders/aggregate`, 
      method: "post", 
      params: pick(req, ["type", "_group", "_count", "_limit", "_offset", "_sort", "_embed", "_expand", "ns_like", "abnormal", "batteryNs_like", "chargeStartAt_lte", "chargeStartAt_gte", "chargeEndAt_lte", "chargeEndAt_gte", "line_like", "stopReason.name_like", "stopReason.level", "swapStartAt_lte", "swapStartAt_gte", "swapEndAt_lte", "swapEndAt_gte", "at_lte", "at_gte", "account", "connectorNo", "chargeSn", "driver", "driverName", "driverPhone", "grabBatteryNo", "isFull", "isReissue", "line", "model", "ns", "plate", "pushBatteryNo", "source", "station", "stationNo", "stopAbnormal", "swapSn", "vin"])
    })
  }
  getOrder = (req: schemas.GetOrderRequest, config: AxiosRequestConfig = {}): Response<schemas.Order> => {
    checkPathParams("getOrder", req, ["order"])
    return this.client.request({
      ...config,
      url: `/orders/${req.order}`, 
      method: "get", 
    })
  }
  updateOrder = (req: schemas.UpdateOrderRequest, config: AxiosRequestConfig = {}): Response<schemas.Order> => {
    checkPathParams("updateOrder", req, ["order"])
    return this.client.request({
      ...config,
      url: `/orders/${req.order}`, 
      method: "patch", 
      data: req.body
    })
  }
  deleteOrder = (req: schemas.DeleteOrderRequest, config: AxiosRequestConfig = {}): Response<void> => {
    checkPathParams("deleteOrder", req, ["order"])
    return this.client.request({
      ...config,
      url: `/orders/${req.order}`, 
      method: "delete", 
    })
  }
  upsertOrder = (req: schemas.UpsertOrderRequest, config: AxiosRequestConfig = {}): Response<schemas.Order> => {
    return this.client.request({
      ...config,
      url: `/orders/upsert`, 
      method: "post", 
      data: req.body
    })
  }
  createRateTemplate = (req: schemas.CreateRateTemplateRequest, config: AxiosRequestConfig = {}): Response<schemas.RateTemplate> => {
    return this.client.request({
      ...config,
      url: `/rate-templates`, 
      method: "post", 
      data: req.body
    })
  }
  listRateTemplates = (req: schemas.ListRateTemplatesRequest, config: AxiosRequestConfig = {}): Response<schemas.RateTemplate[]> => {
    return this.client.request({
      ...config,
      url: `/rate-templates`, 
      method: "get", 
      params: pick(req, ["type", "_limit", "_offset", "_sort", "name"])
    })
  }
  getRateTemplate = (req: schemas.GetRateTemplateRequest, config: AxiosRequestConfig = {}): Response<schemas.RateTemplate> => {
    checkPathParams("getRateTemplate", req, ["rateTemplate"])
    return this.client.request({
      ...config,
      url: `/rate-templates/${req.rateTemplate}`, 
      method: "get", 
    })
  }
  updateRateTemplate = (req: schemas.UpdateRateTemplateRequest, config: AxiosRequestConfig = {}): Response<schemas.RateTemplate> => {
    checkPathParams("updateRateTemplate", req, ["rateTemplate"])
    return this.client.request({
      ...config,
      url: `/rate-templates/${req.rateTemplate}`, 
      method: "patch", 
      data: req.body
    })
  }
  deleteRateTemplate = (req: schemas.DeleteRateTemplateRequest, config: AxiosRequestConfig = {}): Response<void> => {
    checkPathParams("deleteRateTemplate", req, ["rateTemplate"])
    return this.client.request({
      ...config,
      url: `/rate-templates/${req.rateTemplate}`, 
      method: "delete", 
    })
  }
  createStation = (req: schemas.CreateStationRequest, config: AxiosRequestConfig = {}): Response<schemas.Station> => {
    return this.client.request({
      ...config,
      url: `/stations`, 
      method: "post", 
      data: req.body
    })
  }
  listStations = (req: schemas.ListStationsRequest, config: AxiosRequestConfig = {}): Response<schemas.Station[]> => {
    return this.client.request({
      ...config,
      url: `/stations`, 
      method: "get", 
      params: pick(req, ["type", "_count", "_limit", "_offset", "_sort", "ns_like", "name_like", "name", "ns", "no", "source"])
    })
  }
  getStation = (req: schemas.GetStationRequest, config: AxiosRequestConfig = {}): Response<schemas.Station> => {
    checkPathParams("getStation", req, ["station"])
    return this.client.request({
      ...config,
      url: `/stations/${req.station}`, 
      method: "get", 
    })
  }
  updateStation = (req: schemas.UpdateStationRequest, config: AxiosRequestConfig = {}): Response<schemas.Station> => {
    checkPathParams("updateStation", req, ["station"])
    return this.client.request({
      ...config,
      url: `/stations/${req.station}`, 
      method: "patch", 
      data: req.body
    })
  }
  deleteStation = (req: schemas.DeleteStationRequest, config: AxiosRequestConfig = {}): Response<void> => {
    checkPathParams("deleteStation", req, ["station"])
    return this.client.request({
      ...config,
      url: `/stations/${req.station}`, 
      method: "delete", 
    })
  }
  getStationReserveSlots = (req: schemas.GetStationReserveSlotsRequest, config: AxiosRequestConfig = {}): Response<schemas.ReserveSlot[]> => {
    checkPathParams("getStationReserveSlots", req, ["station"])
    return this.client.request({
      ...config,
      url: `/stations/${req.station}/reserve-slots`, 
      method: "get", 
    })
  }
  getJiuXingStationList = (req: schemas.GetJiuXingStationListRequest, config: AxiosRequestConfig = {}): Response<schemas.JiuXingStation[]> => {
    return this.client.request({
      ...config,
      url: `/stations/monitor/jiuxing_station`, 
      method: "get", 
    })
  }
  getStationMonitor = (req: schemas.GetStationMonitorRequest, config: AxiosRequestConfig = {}): Response<schemas.StationMonitor[]> => {
    checkPathParams("getStationMonitor", req, ["station"])
    return this.client.request({
      ...config,
      url: `/stations/${req.station}/monitor`, 
      method: "get", 
    })
  }
  startPlay = (req: schemas.StartPlayRequest, config: AxiosRequestConfig = {}): Response<schemas.DeviceFlv> => {
    checkPathParams("startPlay", req, ["deviceid"])
    return this.client.request({
      ...config,
      url: `/stations/${req.deviceid}/start_play`, 
      method: "get", 
    })
  }
  startPlayback = (req: schemas.StartPlaybackRequest, config: AxiosRequestConfig = {}): Response<schemas.DeviceFlv> => {
    return this.client.request({
      ...config,
      url: `/stations/start_playback`, 
      method: "post", 
      data: req.body
    })
  }
  stopPlayback = (req: schemas.StopPlaybackRequest, config: AxiosRequestConfig = {}): Response<schemas.DeviceFlv> => {
    return this.client.request({
      ...config,
      url: `/stations/stop_playback`, 
      method: "post", 
      data: req.body
    })
  }
  listStationStates = (req: schemas.ListStationStatesRequest, config: AxiosRequestConfig = {}): Response<schemas.StationState[]> => {
    return this.client.request({
      ...config,
      url: `/station-states`, 
      method: "get", 
      params: pick(req, ["no", "name"])
    })
  }
  getStationState = (req: schemas.GetStationStateRequest, config: AxiosRequestConfig = {}): Response<schemas.StationState> => {
    checkPathParams("getStationState", req, ["stationNo"])
    return this.client.request({
      ...config,
      url: `/station-states/${req.stationNo}`, 
      method: "get", 
    })
  }
  updateStationState = (req: schemas.UpdateStationStateRequest, config: AxiosRequestConfig = {}): Response<schemas.StationState> => {
    checkPathParams("updateStationState", req, ["stationNo"])
    return this.client.request({
      ...config,
      url: `/station-states/${req.stationNo}`, 
      method: "patch", 
      data: req.body
    })
  }
  createPile = (req: schemas.CreatePileRequest, config: AxiosRequestConfig = {}): Response<schemas.Pile> => {
    return this.client.request({
      ...config,
      url: `/piles`, 
      method: "post", 
      data: req.body
    })
  }
  listPiles = (req: schemas.ListPilesRequest, config: AxiosRequestConfig = {}): Response<schemas.Pile[]> => {
    return this.client.request({
      ...config,
      url: `/piles`, 
      method: "get", 
      params: pick(req, ["type", "_limit", "_offset", "_sort", "ns_like", "name_like", "name", "no", "ns", "source", "station", "stationNo"])
    })
  }
  getPile = (req: schemas.GetPileRequest, config: AxiosRequestConfig = {}): Response<schemas.Pile> => {
    checkPathParams("getPile", req, ["pile"])
    return this.client.request({
      ...config,
      url: `/piles/${req.pile}`, 
      method: "get", 
    })
  }
  updatePile = (req: schemas.UpdatePileRequest, config: AxiosRequestConfig = {}): Response<schemas.Pile> => {
    checkPathParams("updatePile", req, ["pile"])
    return this.client.request({
      ...config,
      url: `/piles/${req.pile}`, 
      method: "patch", 
      data: req.body
    })
  }
  deletePile = (req: schemas.DeletePileRequest, config: AxiosRequestConfig = {}): Response<void> => {
    checkPathParams("deletePile", req, ["pile"])
    return this.client.request({
      ...config,
      url: `/piles/${req.pile}`, 
      method: "delete", 
    })
  }
  createConnector = (req: schemas.CreateConnectorRequest, config: AxiosRequestConfig = {}): Response<schemas.Connector> => {
    return this.client.request({
      ...config,
      url: `/connectors`, 
      method: "post", 
      data: req.body
    })
  }
  listConnectors = (req: schemas.ListConnectorsRequest, config: AxiosRequestConfig = {}): Response<schemas.Connector[]> => {
    return this.client.request({
      ...config,
      url: `/connectors`, 
      method: "get", 
      params: pick(req, ["type", "status", "_group", "_limit", "_offset", "_sort", "ns_like", "name_like", "name", "no", "ns", "pileNo", "source", "station", "stationNo"])
    })
  }
  getConnector = (req: schemas.GetConnectorRequest, config: AxiosRequestConfig = {}): Response<schemas.Connector> => {
    checkPathParams("getConnector", req, ["connector"])
    return this.client.request({
      ...config,
      url: `/connectors/${req.connector}`, 
      method: "get", 
    })
  }
  updateConnector = (req: schemas.UpdateConnectorRequest, config: AxiosRequestConfig = {}): Response<schemas.Connector> => {
    checkPathParams("updateConnector", req, ["connector"])
    return this.client.request({
      ...config,
      url: `/connectors/${req.connector}`, 
      method: "patch", 
      data: req.body
    })
  }
  deleteConnector = (req: schemas.DeleteConnectorRequest, config: AxiosRequestConfig = {}): Response<void> => {
    checkPathParams("deleteConnector", req, ["connector"])
    return this.client.request({
      ...config,
      url: `/connectors/${req.connector}`, 
      method: "delete", 
    })
  }
  getConnectorChargeState = (req: schemas.GetConnectorChargeStateRequest, config: AxiosRequestConfig = {}): Response<schemas.ChargeState> => {
    checkPathParams("getConnectorChargeState", req, ["connector"])
    return this.client.request({
      ...config,
      url: `/connectors/${req.connector}/state`, 
      method: "get", 
    })
  }
  updateConnectorByNo = (req: schemas.UpdateConnectorByNoRequest, config: AxiosRequestConfig = {}): Response<schemas.Connector> => {
    return this.client.request({
      ...config,
      url: `/connectors/updateByNo`, 
      method: "post", 
      data: req.body
    })
  }
  createCompany = (req: schemas.CreateCompanyRequest, config: AxiosRequestConfig = {}): Response<schemas.Company> => {
    return this.client.request({
      ...config,
      url: `/companies`, 
      method: "post", 
      data: req.body
    })
  }
  listCompanies = (req: schemas.ListCompaniesRequest, config: AxiosRequestConfig = {}): Response<schemas.Company[]> => {
    return this.client.request({
      ...config,
      url: `/companies`, 
      method: "get", 
      params: pick(req, ["type", "_count", "_limit", "_offset", "_sort", "id_like", "ns_like", "name_like", "name", "ns"])
    })
  }
  getCompany = (req: schemas.GetCompanyRequest, config: AxiosRequestConfig = {}): Response<schemas.Company> => {
    checkPathParams("getCompany", req, ["company"])
    return this.client.request({
      ...config,
      url: `/companies/${req.company}`, 
      method: "get", 
      params: pick(req, ["_count"])
    })
  }
  updateCompany = (req: schemas.UpdateCompanyRequest, config: AxiosRequestConfig = {}): Response<schemas.Company> => {
    checkPathParams("updateCompany", req, ["company"])
    return this.client.request({
      ...config,
      url: `/companies/${req.company}`, 
      method: "patch", 
      data: req.body
    })
  }
  deleteCompany = (req: schemas.DeleteCompanyRequest, config: AxiosRequestConfig = {}): Response<void> => {
    checkPathParams("deleteCompany", req, ["company"])
    return this.client.request({
      ...config,
      url: `/companies/${req.company}`, 
      method: "delete", 
    })
  }
  createDriver = (req: schemas.CreateDriverRequest, config: AxiosRequestConfig = {}): Response<schemas.Driver> => {
    return this.client.request({
      ...config,
      url: `/drivers`, 
      method: "post", 
      data: req.body
    })
  }
  listDrivers = (req: schemas.ListDriversRequest, config: AxiosRequestConfig = {}): Response<schemas.Driver[]> => {
    return this.client.request({
      ...config,
      url: `/drivers`, 
      method: "get", 
      params: pick(req, ["_limit", "_offset", "_sort", "ns_like", "name_like", "ns", "phone"])
    })
  }
  getDriver = (req: schemas.GetDriverRequest, config: AxiosRequestConfig = {}): Response<schemas.Driver> => {
    checkPathParams("getDriver", req, ["driver"])
    return this.client.request({
      ...config,
      url: `/drivers/${req.driver}`, 
      method: "get", 
    })
  }
  updateDriver = (req: schemas.UpdateDriverRequest, config: AxiosRequestConfig = {}): Response<schemas.Driver> => {
    checkPathParams("updateDriver", req, ["driver"])
    return this.client.request({
      ...config,
      url: `/drivers/${req.driver}`, 
      method: "patch", 
      data: req.body
    })
  }
  deleteDriver = (req: schemas.DeleteDriverRequest, config: AxiosRequestConfig = {}): Response<void> => {
    checkPathParams("deleteDriver", req, ["driver"])
    return this.client.request({
      ...config,
      url: `/drivers/${req.driver}`, 
      method: "delete", 
    })
  }
  createMaintainer = (req: schemas.CreateMaintainerRequest, config: AxiosRequestConfig = {}): Response<schemas.Maintainer> => {
    return this.client.request({
      ...config,
      url: `/maintainers`, 
      method: "post", 
      data: req.body
    })
  }
  listMaintainers = (req: schemas.ListMaintainersRequest, config: AxiosRequestConfig = {}): Response<schemas.Maintainer[]> => {
    return this.client.request({
      ...config,
      url: `/maintainers`, 
      method: "get", 
      params: pick(req, ["_limit", "_offset", "_sort", "ns_like", "name_like", "ns", "phone"])
    })
  }
  getMaintainer = (req: schemas.GetMaintainerRequest, config: AxiosRequestConfig = {}): Response<schemas.Maintainer> => {
    checkPathParams("getMaintainer", req, ["maintainer"])
    return this.client.request({
      ...config,
      url: `/maintainers/${req.maintainer}`, 
      method: "get", 
    })
  }
  updateMaintainer = (req: schemas.UpdateMaintainerRequest, config: AxiosRequestConfig = {}): Response<schemas.Maintainer> => {
    checkPathParams("updateMaintainer", req, ["maintainer"])
    return this.client.request({
      ...config,
      url: `/maintainers/${req.maintainer}`, 
      method: "patch", 
      data: req.body
    })
  }
  deleteMaintainer = (req: schemas.DeleteMaintainerRequest, config: AxiosRequestConfig = {}): Response<void> => {
    checkPathParams("deleteMaintainer", req, ["maintainer"])
    return this.client.request({
      ...config,
      url: `/maintainers/${req.maintainer}`, 
      method: "delete", 
    })
  }
  createChargeRecord = (req: schemas.CreateChargeRecordRequest, config: AxiosRequestConfig = {}): Response<schemas.ChargeRecord> => {
    return this.client.request({
      ...config,
      url: `/charge-records`, 
      method: "post", 
      data: req.body
    })
  }
  listChargeRecords = (req: schemas.ListChargeRecordsRequest, config: AxiosRequestConfig = {}): Response<schemas.ChargeRecord[]> => {
    return this.client.request({
      ...config,
      url: `/charge-records`, 
      method: "get", 
      params: pick(req, ["_limit", "_offset", "_sort", "ns_like", "startAt_lte", "startAt_gte", "endAt_lte", "endAt_gte", "batteryNo", "chargerNo", "ns", "plate", "sn", "source", "station", "swapSn", "vin"])
    })
  }
  getChargeRecord = (req: schemas.GetChargeRecordRequest, config: AxiosRequestConfig = {}): Response<schemas.ChargeRecord> => {
    checkPathParams("getChargeRecord", req, ["chargeRecord"])
    return this.client.request({
      ...config,
      url: `/charge-records/${req.chargeRecord}`, 
      method: "get", 
    })
  }
  updateChargeRecord = (req: schemas.UpdateChargeRecordRequest, config: AxiosRequestConfig = {}): Response<schemas.ChargeRecord> => {
    checkPathParams("updateChargeRecord", req, ["chargeRecord"])
    return this.client.request({
      ...config,
      url: `/charge-records/${req.chargeRecord}`, 
      method: "patch", 
      data: req.body
    })
  }
  deleteChargeRecord = (req: schemas.DeleteChargeRecordRequest, config: AxiosRequestConfig = {}): Response<void> => {
    checkPathParams("deleteChargeRecord", req, ["chargeRecord"])
    return this.client.request({
      ...config,
      url: `/charge-records/${req.chargeRecord}`, 
      method: "delete", 
    })
  }
  createOperation = (req: schemas.CreateOperationRequest, config: AxiosRequestConfig = {}): Response<schemas.Operation> => {
    return this.client.request({
      ...config,
      url: `/operations`, 
      method: "post", 
      data: req.body
    })
  }
  listOperations = (req: schemas.ListOperationsRequest, config: AxiosRequestConfig = {}): Response<schemas.Operation[]> => {
    return this.client.request({
      ...config,
      url: `/operations`, 
      method: "get", 
      params: pick(req, ["_limit", "_offset", "_sort", "ns_like", "createAt_gt", "createAt_lt", "domain", "ns", "name", "user"])
    })
  }
  getOperation = (req: schemas.GetOperationRequest, config: AxiosRequestConfig = {}): Response<schemas.Operation> => {
    checkPathParams("getOperation", req, ["operation"])
    return this.client.request({
      ...config,
      url: `/operations/${req.operation}`, 
      method: "get", 
    })
  }
  updateOperation = (req: schemas.UpdateOperationRequest, config: AxiosRequestConfig = {}): Response<schemas.Operation> => {
    checkPathParams("updateOperation", req, ["operation"])
    return this.client.request({
      ...config,
      url: `/operations/${req.operation}`, 
      method: "patch", 
      data: req.body
    })
  }
  deleteOperation = (req: schemas.DeleteOperationRequest, config: AxiosRequestConfig = {}): Response<void> => {
    checkPathParams("deleteOperation", req, ["operation"])
    return this.client.request({
      ...config,
      url: `/operations/${req.operation}`, 
      method: "delete", 
    })
  }
  createPaper = (req: schemas.CreatePaperRequest, config: AxiosRequestConfig = {}): Response<schemas.Paper> => {
    return this.client.request({
      ...config,
      url: `/papers`, 
      method: "post", 
      data: req.body
    })
  }
  listPapers = (req: schemas.ListPapersRequest, config: AxiosRequestConfig = {}): Response<schemas.Paper[]> => {
    return this.client.request({
      ...config,
      url: `/papers`, 
      method: "get", 
      params: pick(req, ["_limit", "_offset", "_sort", "labels", "sheet"])
    })
  }
  getPaper = (req: schemas.GetPaperRequest, config: AxiosRequestConfig = {}): Response<schemas.Paper> => {
    checkPathParams("getPaper", req, ["paper"])
    return this.client.request({
      ...config,
      url: `/papers/${req.paper}`, 
      method: "get", 
    })
  }
  updatePaper = (req: schemas.UpdatePaperRequest, config: AxiosRequestConfig = {}): Response<schemas.Paper> => {
    checkPathParams("updatePaper", req, ["paper"])
    return this.client.request({
      ...config,
      url: `/papers/${req.paper}`, 
      method: "patch", 
      data: req.body
    })
  }
  deletePaper = (req: schemas.DeletePaperRequest, config: AxiosRequestConfig = {}): Response<void> => {
    checkPathParams("deletePaper", req, ["paper"])
    return this.client.request({
      ...config,
      url: `/papers/${req.paper}`, 
      method: "delete", 
    })
  }
  createReservation = (req: schemas.CreateReservationRequest, config: AxiosRequestConfig = {}): Response<schemas.Reservation> => {
    return this.client.request({
      ...config,
      url: `/reservations`, 
      method: "post", 
      data: req.body
    })
  }
  listReservations = (req: schemas.ListReservationsRequest, config: AxiosRequestConfig = {}): Response<schemas.Reservation[]> => {
    return this.client.request({
      ...config,
      url: `/reservations`, 
      method: "get", 
      params: pick(req, ["status", "_group", "_limit", "_offset", "_sort", "ns_like", "reserveTime_gte", "reserveTime_lte", "ns", "driver", "driverName", "driverPhone", "plate", "station", "swapSn"])
    })
  }
  cancelReservation = (req: schemas.CancelReservationRequest, config: AxiosRequestConfig = {}): Response<schemas.Reservation> => {
    checkPathParams("cancelReservation", req, ["reservation"])
    return this.client.request({
      ...config,
      url: `/reservations/${req.reservation}/cancel`, 
      method: "post", 
    })
  }
  aggregateReservations = (req: schemas.AggregateReservationsRequest, config: AxiosRequestConfig = {}): Response<schemas.ReservationAggregateResult[]> => {
    return this.client.request({
      ...config,
      url: `/reservations/aggregate`, 
      method: "post", 
      params: pick(req, ["status", "_group", "_limit", "_offset", "_sort", "ns_like", "reserveTime_gte", "reserveTime_lte", "ns", "driver", "driverName", "driverPhone", "plate", "station", "swapSn"])
    })
  }
  getReservation = (req: schemas.GetReservationRequest, config: AxiosRequestConfig = {}): Response<schemas.Reservation> => {
    checkPathParams("getReservation", req, ["reservation"])
    return this.client.request({
      ...config,
      url: `/reservations/${req.reservation}`, 
      method: "get", 
    })
  }
  updateReservation = (req: schemas.UpdateReservationRequest, config: AxiosRequestConfig = {}): Response<schemas.Reservation> => {
    checkPathParams("updateReservation", req, ["reservation"])
    return this.client.request({
      ...config,
      url: `/reservations/${req.reservation}`, 
      method: "patch", 
      data: req.body
    })
  }
  deleteReservation = (req: schemas.DeleteReservationRequest, config: AxiosRequestConfig = {}): Response<void> => {
    checkPathParams("deleteReservation", req, ["reservation"])
    return this.client.request({
      ...config,
      url: `/reservations/${req.reservation}`, 
      method: "delete", 
    })
  }
  createSheet = (req: schemas.CreateSheetRequest, config: AxiosRequestConfig = {}): Response<schemas.Sheet> => {
    return this.client.request({
      ...config,
      url: `/sheets`, 
      method: "post", 
      data: req.body
    })
  }
  listSheets = (req: schemas.ListSheetsRequest, config: AxiosRequestConfig = {}): Response<schemas.Sheet[]> => {
    return this.client.request({
      ...config,
      url: `/sheets`, 
      method: "get", 
      params: pick(req, ["_limit", "_offset", "_sort", "title_like", "isTemplate", "labels"])
    })
  }
  getSheet = (req: schemas.GetSheetRequest, config: AxiosRequestConfig = {}): Response<schemas.Sheet> => {
    checkPathParams("getSheet", req, ["sheet"])
    return this.client.request({
      ...config,
      url: `/sheets/${req.sheet}`, 
      method: "get", 
    })
  }
  updateSheet = (req: schemas.UpdateSheetRequest, config: AxiosRequestConfig = {}): Response<schemas.Sheet> => {
    checkPathParams("updateSheet", req, ["sheet"])
    return this.client.request({
      ...config,
      url: `/sheets/${req.sheet}`, 
      method: "patch", 
      data: req.body
    })
  }
  deleteSheet = (req: schemas.DeleteSheetRequest, config: AxiosRequestConfig = {}): Response<void> => {
    checkPathParams("deleteSheet", req, ["sheet"])
    return this.client.request({
      ...config,
      url: `/sheets/${req.sheet}`, 
      method: "delete", 
    })
  }
  createStaffShift = (req: schemas.CreateStaffShiftRequest, config: AxiosRequestConfig = {}): Response<schemas.StaffShift> => {
    return this.client.request({
      ...config,
      url: `/staff-shifts`, 
      method: "post", 
      data: req.body
    })
  }
  listStaffShifts = (req: schemas.ListStaffShiftsRequest, config: AxiosRequestConfig = {}): Response<schemas.StaffShift[]> => {
    return this.client.request({
      ...config,
      url: `/staff-shifts`, 
      method: "get", 
      params: pick(req, ["status", "_expand", "_group", "_limit", "_offset", "_sort", "ns_like", "submitAt_lte", "submitAt_gte", "abnormal", "submitBy", "successors", "station", "ns"])
    })
  }
  aggregateStaffShifts = (req: schemas.AggregateStaffShiftsRequest, config: AxiosRequestConfig = {}): Response<schemas.StaffShiftAggregateResult[]> => {
    return this.client.request({
      ...config,
      url: `/staff-shifts/aggregate`, 
      method: "post", 
      params: pick(req, ["status", "_expand", "_group", "_limit", "_offset", "_sort", "ns_like", "submitAt_lte", "submitAt_gte", "abnormal", "submitBy", "successors", "station", "ns"])
    })
  }
  getStaffShift = (req: schemas.GetStaffShiftRequest, config: AxiosRequestConfig = {}): Response<schemas.StaffShift> => {
    checkPathParams("getStaffShift", req, ["staffShift"])
    return this.client.request({
      ...config,
      url: `/staff-shifts/${req.staffShift}`, 
      method: "get", 
    })
  }
  updateStaffShift = (req: schemas.UpdateStaffShiftRequest, config: AxiosRequestConfig = {}): Response<schemas.StaffShift> => {
    checkPathParams("updateStaffShift", req, ["staffShift"])
    return this.client.request({
      ...config,
      url: `/staff-shifts/${req.staffShift}`, 
      method: "put", 
      data: req.body
    })
  }
  deleteStaffShift = (req: schemas.DeleteStaffShiftRequest, config: AxiosRequestConfig = {}): Response<void> => {
    checkPathParams("deleteStaffShift", req, ["staffShift"])
    return this.client.request({
      ...config,
      url: `/staff-shifts/${req.staffShift}`, 
      method: "delete", 
    })
  }
  confirmStaffShift = (req: schemas.ConfirmStaffShiftRequest, config: AxiosRequestConfig = {}): Response<schemas.StaffShift> => {
    checkPathParams("confirmStaffShift", req, ["staffShift"])
    return this.client.request({
      ...config,
      url: `/staff-shifts/${req.staffShift}/confirm`, 
      method: "post", 
      data: req.body
    })
  }
  createSwapRecord = (req: schemas.CreateSwapRecordRequest, config: AxiosRequestConfig = {}): Response<schemas.SwapRecord> => {
    return this.client.request({
      ...config,
      url: `/swap-records`, 
      method: "post", 
      data: req.body
    })
  }
  listSwapRecords = (req: schemas.ListSwapRecordsRequest, config: AxiosRequestConfig = {}): Response<schemas.SwapRecord[]> => {
    return this.client.request({
      ...config,
      url: `/swap-records`, 
      method: "get", 
      params: pick(req, ["_limit", "_offset", "_sort", "ns_like", "startAt_lte", "startAt_gte", "endAt_lte", "endAt_gte", "ns", "chargeSn", "driver", "driverName", "driverPhone", "grabBatteryNo", "plate", "pushBatteryNo", "sn", "source", "station", "vin"])
    })
  }
  getSwapRecord = (req: schemas.GetSwapRecordRequest, config: AxiosRequestConfig = {}): Response<schemas.SwapRecord> => {
    checkPathParams("getSwapRecord", req, ["swapRecord"])
    return this.client.request({
      ...config,
      url: `/swap-records/${req.swapRecord}`, 
      method: "get", 
    })
  }
  updateSwapRecord = (req: schemas.UpdateSwapRecordRequest, config: AxiosRequestConfig = {}): Response<schemas.SwapRecord> => {
    checkPathParams("updateSwapRecord", req, ["swapRecord"])
    return this.client.request({
      ...config,
      url: `/swap-records/${req.swapRecord}`, 
      method: "patch", 
      data: req.body
    })
  }
  deleteSwapRecord = (req: schemas.DeleteSwapRecordRequest, config: AxiosRequestConfig = {}): Response<void> => {
    checkPathParams("deleteSwapRecord", req, ["swapRecord"])
    return this.client.request({
      ...config,
      url: `/swap-records/${req.swapRecord}`, 
      method: "delete", 
    })
  }
  createWastage = (req: schemas.CreateWastageRequest, config: AxiosRequestConfig = {}): Response<schemas.Wastage> => {
    return this.client.request({
      ...config,
      url: `/wastages`, 
      method: "post", 
      data: req.body
    })
  }
  listWastages = (req: schemas.ListWastagesRequest, config: AxiosRequestConfig = {}): Response<schemas.Wastage[]> => {
    return this.client.request({
      ...config,
      url: `/wastages`, 
      method: "get", 
      params: pick(req, ["_limit", "_offset", "_sort", "month_lte", "month_gte", "ns_like", "ns", "station", "month"])
    })
  }
  getWastage = (req: schemas.GetWastageRequest, config: AxiosRequestConfig = {}): Response<schemas.Wastage> => {
    checkPathParams("getWastage", req, ["wastage"])
    return this.client.request({
      ...config,
      url: `/wastages/${req.wastage}`, 
      method: "get", 
    })
  }
  updateWastage = (req: schemas.UpdateWastageRequest, config: AxiosRequestConfig = {}): Response<schemas.Wastage> => {
    checkPathParams("updateWastage", req, ["wastage"])
    return this.client.request({
      ...config,
      url: `/wastages/${req.wastage}`, 
      method: "patch", 
      data: req.body
    })
  }
  deleteWastage = (req: schemas.DeleteWastageRequest, config: AxiosRequestConfig = {}): Response<void> => {
    checkPathParams("deleteWastage", req, ["wastage"])
    return this.client.request({
      ...config,
      url: `/wastages/${req.wastage}`, 
      method: "delete", 
    })
  }
  batchCreateWorkOrder = (req: schemas.BatchCreateWorkOrderRequest, config: AxiosRequestConfig = {}): Response<schemas.batchCreateWorkOrderResult> => {
    return this.client.request({
      ...config,
      url: `/work-orders/batch/create`, 
      method: "post", 
      data: req.body
    })
  }
  createWorkOrder = (req: schemas.CreateWorkOrderRequest, config: AxiosRequestConfig = {}): Response<schemas.WorkOrder> => {
    return this.client.request({
      ...config,
      url: `/work-orders`, 
      method: "post", 
      data: req.body
    })
  }
  listWorkOrders = (req: schemas.ListWorkOrdersRequest, config: AxiosRequestConfig = {}): Response<schemas.WorkOrder[]> => {
    return this.client.request({
      ...config,
      url: `/work-orders`, 
      method: "get", 
      params: pick(req, ["status", "type", "_group", "_limit", "_offset", "_sort", "id", "assignees", "createAt_lte", "createAt_gte", "submitAt_lte", "submitAt_gte", "devices.id", "devices.no", "devices.ns", "ns_like", "ns", "submitBy"])
    })
  }
  aggregateWorkOrders = (req: schemas.AggregateWorkOrdersRequest, config: AxiosRequestConfig = {}): Response<schemas.WorkOrderAggregateResult[]> => {
    return this.client.request({
      ...config,
      url: `/work-orders/aggregate`, 
      method: "post", 
      params: pick(req, ["status", "type", "_group", "_limit", "_offset", "_sort", "id", "assignees", "createAt_lte", "createAt_gte", "submitAt_lte", "submitAt_gte", "devices.id", "devices.no", "devices.ns", "ns_like", "ns", "submitBy"])
    })
  }
  getWorkOrder = (req: schemas.GetWorkOrderRequest, config: AxiosRequestConfig = {}): Response<schemas.WorkOrder> => {
    checkPathParams("getWorkOrder", req, ["workOrder"])
    return this.client.request({
      ...config,
      url: `/work-orders/${req.workOrder}`, 
      method: "get", 
    })
  }
  updateWorkOrder = (req: schemas.UpdateWorkOrderRequest, config: AxiosRequestConfig = {}): Response<schemas.WorkOrder> => {
    checkPathParams("updateWorkOrder", req, ["workOrder"])
    return this.client.request({
      ...config,
      url: `/work-orders/${req.workOrder}`, 
      method: "patch", 
      data: req.body
    })
  }
  deleteWorkOrder = (req: schemas.DeleteWorkOrderRequest, config: AxiosRequestConfig = {}): Response<void> => {
    checkPathParams("deleteWorkOrder", req, ["workOrder"])
    return this.client.request({
      ...config,
      url: `/work-orders/${req.workOrder}`, 
      method: "delete", 
    })
  }
  closeWorkOrder = (req: schemas.CloseWorkOrderRequest, config: AxiosRequestConfig = {}): Response<schemas.WorkOrder> => {
    checkPathParams("closeWorkOrder", req, ["workOrder"])
    return this.client.request({
      ...config,
      url: `/work-orders/${req.workOrder}/close`, 
      method: "post", 
    })
  }
  submitWorkOrder = (req: schemas.SubmitWorkOrderRequest, config: AxiosRequestConfig = {}): Response<schemas.WorkOrder> => {
    checkPathParams("submitWorkOrder", req, ["workOrder"])
    return this.client.request({
      ...config,
      url: `/work-orders/${req.workOrder}/submit`, 
      method: "post", 
      data: req.body
    })
  }
  handleWorkOrder = (req: schemas.HandleWorkOrderRequest, config: AxiosRequestConfig = {}): Response<schemas.WorkOrder> => {
    checkPathParams("handleWorkOrder", req, ["workOrder"])
    return this.client.request({
      ...config,
      url: `/work-orders/${req.workOrder}/handleResults`, 
      method: "post", 
      data: req.body
    })
  }
  listTboxRecords = (req: schemas.ListTboxRecordsRequest, config: AxiosRequestConfig = {}): Response<schemas.TboxRecord[]> => {
    return this.client.request({
      ...config,
      url: `/tbox-records`, 
      method: "get", 
      params: pick(req, ["direction", "vin", "start", "end", "interval", "limit", "text"])
    })
  }
}

type Response<T> = Promise<AxiosResponse<T>>
type Dict = Record<string, any>;

function checkPathParams(op: string, req: Dict, ks: string[]) {
  ks.forEach((p) => {
    if (!req[p]) {
      throw new Error(`${p} is required for ${op}`)
    }
  })
}

function pick(o: Dict, ks: string[]) {
  return ks.reduce((r, p) => {
    r[p] = o[p];
    return r;
  }, {} as Dict)
}
